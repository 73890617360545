<template>
  <div class="col-12 mr-auto">
    <div class="card">
      <div class="card-body text-left">
        <h5 class="font-weight-bold"> Search </h5>
        <form @submit.prevent="submit">
          <div class="form-group">
            <label for=""> Manufacture </label>
            <select class="form-control" v-model="data.manufacture">
              <option value="">Select Manufacture</option>
              <option v-for="(val, key) in manufactures"
                      :key="key"
                      :value="val.id">
                {{ val.name }}
              </option>
            </select>
          </div>
          <div class="form-group row">
            <div class="col-12 ">
              <label for="">
                SKU
              </label>
              <input type="text" class="form-control" v-model="data.productNumber">
            </div>

          </div>
          <div class="form-group row">
            <div class="col-4">
              <label for="">
                Rim
              </label>
              <input type="text" class="form-control" v-model="data.rim">
            </div>
            <div class="col-4">
              <label for="">
                Width
              </label>
              <input type="text" class="form-control" v-model="data.width">
            </div>
            <div class="col-4">
              <label for="">
                Ratio
              </label>
              <input type="text" class="form-control" v-model="data.ratio">
            </div>
          </div>
          <div class="form-group row">
            <div class="col-4">
              <label for=""> Category</label>
              <input type="text" class="form-control" v-model="data.category">
            </div>
            <div class="col-4">
              <label for="">Sub Category</label>
              <input type="text" class="form-control" v-model="data.subCategory">
            </div>
            <div class="col-4">
              <label for="">Series Category</label>
              <input type="text" class="form-control" v-model="data.seriesCategory">
            </div>
          </div>
          <div class="form-group">
            <button class="btn btn-success btn-block mt-4">
              Search
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import ManufactureServices from '@/service/ManufactureServices'

export default {
  name: 'ProductSearchComponent',
  data () {
    return {
      manufactures: [],
      data: {
        manufacture: '',
        productNumber: '',
        category: '',
        subCategory: '',
        seriesCategory: '',
        rim: '',
        width: '',
        ratio: ''
      }
    }
  },
  watch: {
    query (val) {
      if (val) {
        const { manufacture, productNumber, category, subCategory, seriesCategory, rim, width, ratio } = val
        this.data = {
          manufacture,
          productNumber,
          category,
          subCategory,
          seriesCategory,
          rim,
          width,
          ratio
        }
      }
    }
  },
  computed: {
    query () {
      return this.$route.query
    }
  },
  methods: {
    async getManufacture () {
      try {
        const res = await ManufactureServices.getAll({})
        if (res && res.data) this.manufactures = res.data
      } catch (e) {
        console.log('get manufacture', e, e.response)
      }
    },
    submit () {
      if (
        this.data.manufacture ||
        this.data.productNumber ||
        this.data.category ||
        this.data.rim ||
        this.data.width ||
        this.data.subCategory ||
        this.data.seriesCategory ||
        this.data.ratio
      ) {
        this.$router.push({
          name: 'products',
          query: {
            manufacture: this.data.manufacture,
            productNumber: this.data.productNumber,
            category: this.data.category,
            subCategory: this.data.subCategory,
            seriesCategory: this.data.seriesCategory,
            rim: this.data.rim,
            width: this.data.width,
            ratio: this.data.ratio
          }
        }).catch(e => {

        })
      }
    }
  },
  mounted () {
    this.getManufacture()
    if (this.query) {
      this.data = {
        manufacture: this.query.manufacture,
        productNumber: this.query.productNumber,
        category: this.query.category,
        subCategory: this.query.subCategory,
        seriesCategory: this.query.seriesCategory,
        rim: this.query.rim,
        ratio: this.query.ratio,
        width: this.query.width
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
