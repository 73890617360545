<template>
  <div class="row" data-app>
    <PageHeaderComponent :label="`Send Inboxs`" />
    <div class="col-12">
      <div class="card">
        <div class="card-body text-left">
          <LoadingComponent v-if="isLoading" />
          <form v-else @submit.prevent="submit">
            <div class="form-group">
              <label for="">Recipients</label>

              <div>
                <input class="input-radio" type="radio" id="single" value="single" v-model="recipientType" />
                <label for="single">Single User (Mobile)</label>
                <span style="margin-left: 10px">&nbsp;</span>

                <input class="input-radio" type="radio" id="multi" value="multi" v-model="recipientType" />
                <label for="multi">Multi user (Mobile)</label>
                <span style="margin-left: 10px">&nbsp;</span>

                <input class="input-radio" type="radio" id="all" value="all" v-model="recipientType" />
                <label for="all">All active users</label>
              </div>

              <input
                type="text"
                class="form-control"
                v-model="data.userId"
                required
                placeholder="6280xxx"
                :disabled="recipientType !== 'single' && recipientType !== 'multi'"
              />
            </div>
            <div class="form-group">
              <label for="">Type of message</label>
              <input type="text" class="form-control" v-model="data.type" required />
            </div>
            <div class="form-group">
              <label for="">Sender name</label>
              <input type="text" class="form-control" v-model="data.sender" required />
            </div>
            <div class="form-group">
              <label for="">Priority</label>
              <select class="form-control" v-model="data.priority">
                <option disabled value="">Please select priority</option>
                <option>high</option>
                <option>medium</option>
                <option>low</option>
              </select>
            </div>
            <div class="form-group">
              <label for="">Title</label>
              <input type="text" class="form-control" v-model="data.title" required />
            </div>
            <div class="form-group">
              <label for="">
                Description
              </label>
              <textarea v-model="data.body" rows="5" class="form-control"></textarea>
            </div>
            <div class="form-group">
              <label for="">Action</label>
              <input type="text" class="form-control" v-model="data.action" />
            </div>
            <div class="form-group">
              <label for="">
                Action Data
              </label>
              <textarea v-model="data.actionData" rows="5" class="form-control"></textarea>
            </div>
            <hr />
            <div class="alert alert-info" v-if="lastMessage" v-html="lastMessage"></div>
            <div class="form-group">
              <button class="btn btn-primary btn-block">Submit</button>
            </div>
            <div class="form-group">
              <button class="btn btn-secondary btn-block" v-on:click="back">Back</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import PageHeaderComponent from '@/components/misc/PageHeaderComponent'
  import LoadingComponent from '@/components/misc/LoadingComponent'
  import InboxServices from '@/service/InboxServices'
  // import moment from 'moment'

  export default {
    name: 'CreateNotificationScreen',
    components: { LoadingComponent, PageHeaderComponent },
    data() {
      return {
        isLoading: false,
        lastMessage: '',
        recipientType: 'single',
        data: {
          userId: '',
          type: 'default',
          sender: 'Proban',
          priority: 'low',
          title: '',
          body: '',
          // title: 'The test information',
          // body: 'We are just wrinting test information here',
          action: '',
          actionData: ''
        },
        dateProps: {
          headerColor: 'red'
        },
        timeProps: {
          useSeconds: true,
          ampmInTitle: true
        }
      }
    },
    computed: {
      ...mapState('auth', ['user'])
    },
    methods: {
      async submit() {
        this.isLoading = true
        try {
          const params = Object.entries(this.data).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {})
          params.recipientType = this.recipientType

          // params.sender = this.user.email
          // params.createdTime = moment(params.createdTime, 'DD/MM/YYYY HH:mm:ss')

          const res = await InboxServices.save(params)
          if (res) {
            this.lastMessage = res.data
          }
          this.recipientType = 'single'
          this.data.userId = ''
          // this.lastMessage = 'Inbox Notifications has been sent'
          // await this.$router.push({
          //   name: 'inbox'
          // })
        } catch (e) {
          console.log('error create notif', e, e.response)
          this.lastMessage = e.response
        }
        this.isLoading = false
      },

      async back() {
        await this.$router.push({
          name: 'inbox'
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .input-radio {
    margin-left: 5px;
    margin-right: 5px;
  }
</style>
