<template>
  <div class="row">
    <PageHeaderComponent
      :label="`List of Refund`"
    />
    <DashboardNotificationComponent
      :is-show="isShow"
      :message="message"
      @close="closeNotif"
      class="col-12"
    />
    <div
      v-if="isLoading"
      class="col-12">
      <LoadingComponent/>
    </div>
    <div v-else class="col-12">
      <v-data-table
        :headers="headers"
        :items="data"
        :items-per-page="15"
        class="elevation-1"
      >
        <template v-slot:item="{ item, expand, isExpanded }">
          <RefundItemComponent
            @showAlert="showAlert"
            :data="item"
            @expand="expand(!isExpanded)"
            :isExpanded="isExpanded"
          />
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <RefundExpandedContent :data="item" />
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import PageHeaderComponent from '@/components/misc/PageHeaderComponent'
import LoadingComponent from '@/components/misc/LoadingComponent'
import RefundService from '@/service/RefundService'
import RefundItemComponent from '@/views/refund/components/RefundItemComponent'
import RefundExpandedContent from '@/views/refund/components/RefundExpandedComponent'
import DashboardNotificationComponent from '@/views/dashboard/component/DashboardNotificationComponent'

export default {
  name: 'RefundScreen',
  components: { RefundItemComponent, LoadingComponent, PageHeaderComponent, DashboardNotificationComponent, RefundExpandedContent },
  data () {
    return {
      isLoading: true,
      isShow: false,
      message: '',
      data: [],
      headers: [
        {
          text: 'User',
          align: 'start',
          sortable: true,
          value: 'user'
        },
        { text: 'Trx Amount', value: 'amount' },
        {
          text: 'Created At',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: 'Status', value: status },
        { text: 'Notes', value: status },
        { text: 'Action', value: 'createdTime' },
        { text: 'Toggle', value: 'toggle', sortable: false }
      ]
    }
  },
  computed: {
    query () {
      return this.$route.query
    }
  },
  methods: {
    closeNotif () {
      this.isShow = false
    },
    showAlert (e) {
      this.message = e.message
      this.isShow = true
    },
    async get () {
      this.isLoading = true
      try {
        const params = {
          filter: {
            include: [
              {
                relation: 'user'
              }
            ],
            order: 'createdTime DESC'
          }
        }

        params.filter = JSON.stringify(params.filter)

        const res = await RefundService.getAll(params)
        if (res) this.data = res.data
      } catch (e) {
        console.log('get refund list error', e)
      }
      this.isLoading = false
    }
  },
  mounted () {
    this.get()
  }
}
</script>
<style lang="scss" scoped></style>
