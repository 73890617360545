//Login.vue
<template>
  <div class="row justify-content-md-center mainContainer">
    <div class="col-md-6">
        <img width="250px" alt="Proban Logo" class="img-fluid" src="../../assets/images/probanlogo.jpeg">

      <div class="alert alert-danger" v-if="errors" v-html="errors"></div>
      <div class="alert alert-success" v-if="info" v-html="info"></div>
      <div class="card">
        <div class="card-header">Permintaan Non Aktif Akun Permanen</div>
        <div class="card-body">
          <form @submit.prevent="userDelete">
            <div class="form-group">
              <label for="email">Email</label>
              <input v-model="form.email" type="email" class="form-control" placeholder="Email">
            </div>
            <div class="form-group">
              <label for="mobile">Mobile Number</label>
              <div class="input-group w-auto">
                <input v-model='form.mobile' type="number" class="form-control" placeholder="Mobile Number">
                <button class="btn btn-primary" type="button" @click='sendCode'>
                  Send Code
                </button>
              </div>
            </div>

            <div v-if="isSent" class="form-group">
              <label for="code">Code</label>
              <div class="input-group w-auto">
                <input v-model='form.code' type="number" class="form-control" placeholder="Code">
              </div>
            </div>
            <div class="form-group">
            </div>
            <!--
            <a href="#" style="float:right;" @click="showModal = true">Forgot Password?</a><br>
            -->
            <div v-if="isSent" class="form-group">
              <input
                type="checkbox"
                id="checkbox"
                v-model="isAgree"
                class="form-check-input"
              /> Dengan ini saya meminta agar akun dan informasi pribadi saya yang diproses oleh Proban akan di nonaktifkan permanen.
            </div>

            <button v-if="isSent" type="submit" class="btn btn-success text-white">Submit</button>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Forgot Your Password?</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" @click="showModal = false">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>Please contact HQ Admin to reset your password.</p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" @click="showModal = false">Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
// import { mapMutations } from 'vuex'
// import Helper from '../../utils/Helper'
import UserDeletionServices from '@/service/UserDeletionServices'

/*
  TODO for dev only harus di hapus
  email admin
  admin@pro-ban.com
  Proban2015!!#
  Proban2021!!#

   email merchant
   merchant1@pro-ban.com
   Proban2015!!#
   Proban2021!!#
 */

export default {
  data () {
    return {
      form: {
        email: '',
        mobile: '',
        code: '',
      },
      isAgree: false,
      errors: null,
      isError: false,
      showModal: false,
      info: null,
      isSent: false,
    }
  },
  methods: {
    async userDelete() {
      try {
        if (this.form.email.trim() === '' ||
          this.form.mobile.trim() === '' ||
          this.form.code.trim() === '' )
        {
          this.errors = 'Please input all the fields.'
        } else {

          if (this.isAgree) {
            const res = await UserDeletionServices.deleteUser(this.form)
            if (res) {
              const { data } = res
              if (data) {
                if (data.message === 'SUCCESS') {
                  this.info = 'Data ini sudah dinonaktifkan permanen.';
                  this.form.mobile = '';
                  this.form.email = '';
                  this.form.code = '';
                } else {
                  this.errors = data;
                  this.isError = true;
                }
              }
            }
          } else {
            this.errors = 'Mohon maaf Anda harus menyetujui persyaratan ini sebelum melanjutkan.'
          }
        }
      } catch (e) {
        let message = 'Something Went Wrong'
        console.log(e.response.data.error.message)
        // if (e && e.response && e.response.data && e.response.data.error) message = Helper.generateErrorMessage(e.response.data.error.message)
        if (e && e.response && e.response.data && e.response.data.error) message = e.response.data.error.message
        this.errors = message
        this.isError = true
      }

      setTimeout(() => {
        this.errors = ''
        this.info = ''
      }, 10000)
    },
    async sendCode () {
      try {
        if (this.form.email.trim() === '' || this.form.mobile.trim() === '') {
          this.errors = 'Mohon input email dan nomor hp anda.'
        } else {
          const res = await UserDeletionServices.sendCode(this.form)
          if (res) {
            const { data } = res
            if (data) {
              if (data.message === 'SUCCESS') {
                this.info = 'Kode sudah dikirim ke email anda.';
                this.isSent = true;
              } else {
                this.errors = data;
                this.isError = true;
              }
            }
          }
        }
      } catch (e) {
        let message = 'Something Went Wrong'
        console.log(e.response.data.error.message)
        // if (e && e.response && e.response.data && e.response.data.error) message = Helper.generateErrorMessage(e.response.data.error.message)
        if (e && e.response && e.response.data && e.response.data.error) message = e.response.data.error.message
        this.errors = message
        this.isError = true
      }

      setTimeout(() => {
        this.errors = ''
        this.info = ''
      }, 3000)
    }
  },
  mounted () {
    const token = this.$cookies.get('token')
    if (token) this.$router.push('/dashboard')
  }
}
</script>
<style lang="scss" scoped>
  .mainContainer{
    background-color:#fff;
    min-height:800px;
  }
  .modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>
