<template>
  <div class="row">
    <PageHeaderComponent
      :label="`Inbox & Notification`"
      :button-label="`Send Notification`"
      @btn-click="sendMessage"
    />
    <div class="col-12">
      <LoadingComponent v-if="isLoading" />
      <v-data-table
        v-else
        :headers="headers"
        :items="data"
        :items-per-page="15"
        class="elevation-1"
        :search="search"
      >
        <template v-slot:top>
          <div class="row">
            <div class="col-6 ml-auto">
              <input type="text" v-model="search" class="form-control" placeholder="search">
            </div>
          </div>
        </template>
        <template v-slot:item="{ item }">
        <InboxItemComponent
          :data="item"
          @delete="handleDelete"
        />
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import PageHeaderComponent from '@/components/misc/PageHeaderComponent'
import LoadingComponent from '@/components/misc/LoadingComponent'
import InboxServices from '@/service/InboxServices'
import InboxItemComponent from '@/views/inbox/InboxItemComponent'
export default {
  name: 'InboxScreen',
  components: { InboxItemComponent, LoadingComponent, PageHeaderComponent },
  data () {
    return {
      data: [],
      isLoading: false,
      search: '',
      headers: [
        {
          text: 'Title',
          align: 'start',
          sortable: true,
          value: 'title'
        },
        { text: 'Broadcast Time', value: 'Broadcast Time' },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    }
  },
  methods: {
    sendMessage () {
      this.$router.push({
        name: 'create-notification'
      })
    },
    async getData () {
      const filter = {
          order: ['createdTime desc']
      }
      this.isLoading = false
      try {
        const res = await InboxServices.getAll(`filter=${JSON.stringify(filter)}`)
        if (res && res.data) this.data = res.data
      } catch (e) {
        console.log('get inbox error', e, e.response)
      }
    },
    handleDelete (e) {
      this.data = this.data.filter((v) => {
        return v.id !== e
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style lang="scss" scoped></style>
