<template>
  <div class="container-expand text-left">
    <div class="row">
      <div class="col-6">
        <p>Order ID : {{ data.orderId }}</p>
        <p>Name : {{ data.user ? data.user.name : '-' }}</p>
        <p>Membership ID: {{ data.user ? data.user.membershipId : '-' }}</p>
      </div>
      <div class="col-6" v-if="data.user">
        <p>Email Address : {{ data.user.email }}</p>
        <p>Mobile Phone {{ data.user.mobile }}</p>
        <p>Gender : {{ data.user.gender }}</p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'RefundExpandedContent',
    props: {
      data: {
        type: Object,
        required: true
      }
    }
  }
</script>
<style lang="scss" scoped>
  .container-expand {
    background-color: #eee;
    padding: 1.5rem;
  }
</style>
