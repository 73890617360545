<template>
  <tr>
    <td>
      {{ data.title }}
    </td>
    <td>
      {{ $helper.dateFormatter(data.createdTime)}}
    </td>
    <td>
      <div
        @click="remove"
        class="btn btn-danger btn-sm">
        <i class="fas fa-trash"></i>
      </div>
    </td>
  </tr>
</template>
<script>
import InboxServices from '@/service/InboxServices'

export default {
  name: 'InboxItemComponent',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    async remove () {
      try {
        await InboxServices.delete(this.data.id)
        this.$emit('delete', this.data.id)
      } catch (e) {
        console.log('remove error', e, e.response)
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
