<template>
  <div class="row">
    <PageHeaderComponent
      :label="`Member Detail`"
    />
    <div v-if="isLoading" class="col-12">
      <LoadingComponent />
    </div>
    <div v-else class="col-12">
      <div class="row mb-3">
        <div class="col-lg-5 col-sm-12 mb-3">
          <div class="card">
            <div class="card-header bg-danger text-white">
              General Information
            </div>
            <div class="card-body text-left">
              <p>
                Membership Id : {{ data.membershipId }}
              </p>
              <p>
                Full Name : {{ data.name }}
              </p>
              <p>
                Phone : {{ data.mobile }}
              </p>
              <p>
                Email : {{ data.email }}
              </p>
              <p>
                Gender : {{ data.gender }}
              </p>
              <p>
                Birthdate: {{$helper.dateFormatterMemberDetail(data.birthDate, true)}}
              </p>
              <p>
                CitizenId: {{data.citizenId}}
              </p>
              <p>
                Class : {{data.class}}
              </p>
              <p>
                Created Time: {{ $helper.dateFormatter(data.createdTime, true)}}
              </p>
              <p>
                Updated Time: {{ $helper.dateFormatter(data.updatedTime, true)}}
              </p>
              <p>
                <strong> Total Point : {{ totalPoint.balance ? totalPoint.balance : '-'}}</strong>
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-sm-12 mb-3">
          <UserVehicleComponent />
          <UserDeviceComponent />
        </div>
      </div>
      <div class="roy">
        <MemberVoucher />
      </div>
      <div class="row mb-3">
        <PageHeaderComponent
          :label="`Transactions`"
        />
        <UserTransactionComponent />
      </div>
    </div>
  </div>
</template>
<script>
import PageHeaderComponent from '@/components/misc/PageHeaderComponent'
import LoadingComponent from '@/components/misc/LoadingComponent'
import UserServices from '@/service/UserServices'
import UserDeviceComponent from '@/views/member/component/UserDeviceComponent'
import UserVehicleComponent from '@/views/member/component/UserVehicleComponent'
import UserTransactionComponent from '@/views/member/component/UserTransactionComponent'
import MemberVoucher from '@/views/member/MemberVoucher'
export default {
  name: 'MemberDetailScreen',
  components: { UserTransactionComponent, UserVehicleComponent, UserDeviceComponent, LoadingComponent, PageHeaderComponent, MemberVoucher },
  data () {
    return {
      isLoading: true,
      data: null,
      totalPoint: null
    }
  },
  methods: {
    async getDetail () {
      this.isLoading = true
      try {
        const res = await UserServices.get(this.$route.params.id)
        if (res.data) this.data = res.data
        this.loadPoint()
      } catch (e) {
        console.log('get user error')
      }
      this.isLoading = false
    },
    async loadPoint () {
      console.log(this.data)
      this.isLoading = true
      try {
        const res2 = await UserServices.getPoint(this.data.membershipId)
        if (res2.data) this.totalPoint = res2.data
      } catch (e) {
        console.log('get point error')
      }
      this.isLoading = false
    }
  },
  mounted () {
    this.getDetail()
  }
}
</script>
<style lang="scss" scoped></style>
