import axios from 'axios'
import Vue from 'vue'

export function setHeaderToken () {
  const token = Vue.$cookies.get('token')
  axios.defaults.headers.common.Authorization = 'Bearer ' + token
}

export function removeHeaderToken () {
  delete axios.defaults.headers.common.Authorization
}
