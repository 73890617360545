<template>
  <div class="row" data-app>
    <PageHeaderComponent
      :label="` List Of Voucher`"
      :button-label="`Create Voucher`"
      @btn-click="createVoucher"
    />
    <div class="col-12">
      <LoadingComponent v-if="isLoading"/>
      <v-data-table
        v-else
        :headers="headers"
        :items="data"
        :items-per-page="15"
        class="elevation-1"
        :search="search"
      >
        <template v-slot:top>
          <div class="col-12">
            <div class="row">
              <div class="col-6 ml-auto">
                <input type="text" v-model="search" class="form-control" placeholder="search">
              </div>
            </div>
          </div>
        </template>
        <template v-slot:item="{ item, expand, isExpanded }">
          <VoucherItemComponent :data="item"  @expand="expand(!isExpanded)"
                :isExpanded="isExpanded"/>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <VoucherItemDetail
                  @save="updateData($event, item.id)"
                  :data="item"/>
              </td>
            </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import PageHeaderComponent from '@/components/misc/PageHeaderComponent'
import VoucherItemDetail from '@/views/voucher/components/VoucherItemDetail'
import LoadingComponent from '@/components/misc/LoadingComponent'
import VoucherItemComponent from '@/views/voucher/VoucherItemComponent'
import VoucherServices from '@/service/VoucherService'

export default {
  name: 'voucherList',
  components: { PageHeaderComponent, LoadingComponent, VoucherItemComponent, VoucherItemDetail },
  data () {
    return {
      isLoading: false,
      search: '',
      data: [],
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id'
        },
        {
          text: 'Voucher',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Discount Type',
          align: 'start',
          sortable: true,
          value: 'discountType'
        },
        {
          text: 'Discount Value',
          align: 'start',
          sortable: true,
          value: 'discountValue'
        },
        {
          text: 'Usage Type',
          align: 'start',
          sortable: true,
          value: 'usageType'
        },
        {
          text: 'Status',
          align: 'start',
          sortable: true,
          value: 'status'
        },
        {
          text: 'Created Date',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Updated Time',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: 'Action', value: 'action', sortable: false },
        { text: 'Toggle', value: 'toggle', sortable: false }
      ]
    }
  },
  methods: {
    createVoucher () {
      this.$router.push({
        name: 'add-voucher'
      })
    },
    async getAll () {
      this.isLoading = true
      try {
        const params = {
          filter: {include: [
              {
                relation: 'product'
              }]}
        }
        params.filter = JSON.stringify(params.filter)

        const res = await VoucherServices.getAll(params)
        if (res && res.data) this.data = res.data
      } catch (e) {
        console.log('get data voucher', e, e.response)
      }
      this.isLoading = false
    },
    handleDelete (e) {
      this.promos = this.promos.filter((v) => {
        return v.id !== e
      })
    }
  },
  mounted () {
    this.getAll()
  }
}
</script>
