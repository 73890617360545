<template>
  <div class="row">
    <PageHeaderComponent
      :label="`List of Products`"
      :button-label="`Sync Products`"
      @btn-click="syncProduct"
    />
    <ProductSearchComponent/>
    <div class="col-12">
      <LoadingComponent v-if="isLoading"/>
          <v-data-table
            v-else
            :headers="headers"
            :items="products"
            :items-per-page="15"
            class="elevation-1"
          >
            <template v-slot:item="{ item, expand, isExpanded }">
              <ProductItemComponent
                :data="item"
                @expand="expand(!isExpanded)"
                :isExpanded="isExpanded"
              />
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <ProductItemDetail
                  @save="updateData($event, item.id)"
                  :data="item"/>
              </td>
            </template>
          </v-data-table>
    </div>
  </div>
</template>
<script>
import PageHeaderComponent from '@/components/misc/PageHeaderComponent'
import ProductSearchComponent from '@/views/product/ProductSearchComponent'
import ProductItemDetail from '@/views/product/ProductItemDetail'
import LoadingComponent from '@/components/misc/LoadingComponent'
import ProductItemComponent from '@/views/product/ProductItemComponent'
import ProductServices from '@/service/ProductServices'

export default {
  name: 'ProductScreen',
  components: { ProductItemComponent, LoadingComponent, ProductSearchComponent, PageHeaderComponent, ProductItemDetail },
  data () {
    return {
      isLoading: false,
      products: [],
      headers: [
        {
          text: 'Product Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Manufacture',
          align: 'start',
          sortable: true,
          value: 'manufacture'
        },
        {
          text: 'SKU',
          align: 'start',
          sortable: true,
          value: 'sku'
        },
        {
          text: 'Categories',
          align: 'start',
          sortable: true,
          value: 'rim'
        },
        {
          text: 'Price',
          align: 'start',
          sortable: true,
          value: 'createdDate'
        },
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Toggle', value: 'toggle', sortable: false }
      ]
    }
  },
  watch: {
    '$route.query': function (val) {
      this.getData()
    }
  },
  computed: {
    query () {
      return this.$route.query
    }
  },
  methods: {
    updateData (data, id) {
      this.products.map((v) => {
        if (id === v.id) {
          v = data
        }
        return v
      })
    },
    async syncProduct () {
      this.isLoading = true
      try {
        await ProductServices.sync()
        window.location.reload()
      } catch (e) {
        console.log('error', e)
      }
      this.isLoading = false
    },
    async getData () {
      this.isLoading = true
      try {
        let params = {}

        const { manufacture, productNumber, rim, width, ratio, category, subCategory, seriesCategory } = this.query

        if (manufacture || productNumber || rim || width || ratio || category || subCategory || seriesCategory) {
          params = {
            filter: {
              where: {}
            }
          }

          const paramsAnd = []
          if (manufacture) {
            paramsAnd.push({ manufactureId: manufacture })
          }
          if (category) paramsAnd.push({ category })
          if (subCategory) paramsAnd.push({ subCategory })
          if (seriesCategory) paramsAnd.push({ seriesCategory })

          if (productNumber) {
            paramsAnd.push({ sku: productNumber })
          }

          if (rim) paramsAnd.push({ rim })
          if (width) paramsAnd.push({ width })
          if (ratio) paramsAnd.push({ ratio })

          if (paramsAnd.length) {
            params.filter.where = {
              ...params.filter.where,
              ...{
                and: paramsAnd
              }
            }
          }

          params.filter = JSON.stringify(params.filter)
        }

        const res = await ProductServices.getAll(params)
        if (res && res.data) this.products = res.data
      } catch (e) {
        console.log('get products error', e, e.response)
      }
      this.isLoading = false
    },
    handleDelete (e) {
      this.products = this.products.filter((v) => {
        return v.id !== e
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style lang="scss" scoped></style>
