<template>
  <div ref="cancelAlert" v-if="isShow"
       :class="[classAlert]"
       class="alert  alert-dismissible fade show position-relative" role="alert">
    <strong>
      {{ message }}
    </strong>
    <button @click="$emit('close')" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
      <i class="fas fa-times-circle"></i>
    </button>
  </div>
</template>
<script>
export default {
  name: 'DashboardNotificationComponent',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: 'Hello notification'
    },
    classAlert: {
      type: String,
      default: 'alert-warning'
    }
  }
}
</script>
<style lang="scss" scoped>
.btn-close {
  position: absolute;
  right: 15px;
  top: 15px;
}
</style>
