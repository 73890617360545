<template>
    <div class="row" data-app>
        <PageHeaderComponent
            :label="`Add Voucher`"
        />
        <div class="col-12 mb-3">
            <div class="card">
                <div class="card-header">
                Add Voucher
                </div>
                <div class="card-body text-left">
                    <form @submit.prevent="submit">
                        <div class="form-row">
                            <div class="form-group col-12">
                                <label for="">Voucher Name</label>
                                <input v-model="data.name" type="text" class="form-control" required>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6">
                                <label for="">Discount Type</label>
                                <select v-model="discountType" class="form-control">
                                    <option>Choose Discount Type</option>
                                    <option value="percentage">Percentage</option>
                                    <option value="amount">Amount</option>
                                    <option value="free">Free</option>
                                </select>
                            </div>
                            <div class="form-group col-6">
                                <label for="">Product ID</label>
                                <input v-model="data.productId" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6">
                                <label for="">Max Value</label>
                                <input :disabled="discountType === 'free'" v-model="data.maxValue" type="text" class="form-control" placeholder='ex:100000'>
                            </div>
                            <div class="form-group col-6">
                                <label for="">Discount Value</label>
                                <input :disabled="discountType === 'free'" v-model="data.discountValue" type="text" class="form-control" required placeholder='ex:20%'>
                            </div>
                        </div>
                        <div class="form-row">
                          <div class="form-group col-sm-6">
                              <label for="">Status</label>
                              <select v-model="data.status" class="form-control">
                                <option>Choose Status </option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                              </select>
                          </div>
                          <div class="form-group col-sm-6">
                              <label for="">Usage Type</label>
                              <select v-model="data.usageType" class="form-control">
                                <option>Choose Usage Type </option>
                                <option value="online">Online</option>
                                <option value="offline">Offline</option>
                              </select>
                          </div>
                        </div>
                        <div class="form-group">
                            <label for="">Description</label>
                            <div class="row">
                              <div class="col-6">
                                <textarea v-model="data.description" rows="6" class="form-control"></textarea>
                              </div>
                              <div class="col-6" v-if="data.description" v-html="compiledMarkdownDesc"></div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Notes</label>
                            <div class="row">
                              <div class="col-6">
                                <textarea v-model="data.notes" rows="6" class="form-control"></textarea>
                              </div>
                              <div class="col-6" v-if="data.notes" v-html="compiledMarkdownNotes"></div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Terms of Use</label>
                            <div class="row">
                              <div class="col-6">
                              <textarea v-model="data.termsOfUse" rows="6" class="form-control"></textarea>
                              </div>
                              <div class="col-6" v-if="data.termsOfUse" v-html="compiledMarkdowntermsOfUse"></div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">
                                Upload Image
                            </label>
                            <UploadFileComponent
                                :url="data.imageUrl"
                                @uploaded="data.imageUrl = $event"
                            />
                        </div>
                        <div class="form-group">
                            <button class="btn btn-success btn-block">SAVE</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PageHeaderComponent from '@/components/misc/PageHeaderComponent'
import UploadFileComponent from '@/components/misc/UploadFileComponent'
import VoucherServices from '@/service/VoucherService'
import marked from 'marked'
import _ from 'lodash'
export default {
  name: 'VoucherList',
  components: { PageHeaderComponent, UploadFileComponent },
  data () {
    return {
      isLoading: false,
      data: {
        createdTime: new Date(),
        description: '',
        discountType: '',
        discountValue: '',
        imageUrl: '',
        name: '',
        maxValue: '',
        notes: '',
        productId: '',
        termsOfUse: '',
        status: '',
        usageType: '',
        updatedTime: new Date()
      },
      discountType: '',
      dateProps: {
        headerColor: 'red'
      },
      timeProps: {
        useSeconds: true,
        ampmInTitle: true
      }
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    compiledMarkdownDesc: function () {
      return marked(this.data.description, { sanitize: true })
    },
    compiledMarkdownNotes: function () {
      return marked(this.data.notes, { sanitize: true })
    },
    compiledMarkdowntermsOfUse: function () {
      return marked(this.data.termsOfUse, { sanitize: true })
    }
  },
  methods: {
    update: _.debounce(function (e) {
      this.input = e.target.value
    }, 300),
    async submit () {
      const params = this.data
      params.discountType = this.discountType
      console.log(params.discountType)
      if (params.discountType === 'free') {
        params.discountValue = Number(0)
        params.maxValue = Number(0)
      } else {
        params.discountValue = Number(params.discountValue)
        params.maxValue = Number(params.maxValue)
      }
      this.isLoading = true
      try {
        let res = null
        if (this.$route.params.id) {
          res = await VoucherServices.update(params, this.$route.params.id)
        } else {
          res = await VoucherServices.save(params)
        }
        if (res) await this.$router.push({ name: 'voucherList' })
      } catch (e) {
        console.log('error create Voucher', e, e.response)
      }
      this.isLoading = false
    },
    async getDetail (id) {
      this.isLoading = true
      try {
        const res = await VoucherServices.get(id)
        if (res) {
          const data = res.data
          this.data = data
          this.discountType = data.discountType
        }
      } catch (e) {
        this.isError = true
        console.log('error get detail', e, e.response)
      }
      this.isLoading = false
    }
  },
  mounted () {
    if (this.id) this.getDetail(this.id)
  }
}
</script>
