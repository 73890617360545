<template>
  <div
    v-if="!isLoading"
    class="card textLeft">
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          Name: {{ mutateData.name ? mutateData.name : '-' }} <br>
          Category: {{ mutateData.category }} / {{ mutateData.subCategory }} / {{ mutateData.seriesCategory }} <br>
          Price: {{ mutateData.price }}<br>
          Spec: {{ mutateData.spec }}
        </div>
        <div class="col-6">
          ID: {{ mutateData.id }} <br>
          SKU: {{ mutateData.sku }}<br>
          Manufacture ID: {{ mutateData.manufactureId }} <br>
          Created Time: {{ this.$helper.dateFormatter(mutateData.createdTime, true) }} <br>
          Updated Time: {{ this.$helper.dateFormatter(mutateData.updatedTime, true) }}
        </div>
        <div class="col-12">
          <div class="col-12" style = "float:left;">
            <div class="form-group">
              <label for="">Show On App</label>
              <select v-model="mutateData.status" class="form-control" required>
                <option value="active"> Active</option>
                <option value="inactive"> Inactive</option>

              </select>
            </div>
            <div class="form-group">
              <label for="">Description</label>
              <div class="row">
                <textarea v-model="mutateData.description" class="form-control col-6" rows="6"></textarea>
                <div v-if="mutateData.description" class="col-6" v-html="compiledMarkdown"></div>
              </div>
            </div>
            <div class="form-group">
              <label for="">Thumbnail</label>
              <div v-if="mutateData.thumbnailUrl" class="mb-4"><button class="btn btn-danger" @click="delThumbnail">Delete Thumbnail</button></div>
                <UploadFileComponent
                  :url="mutateData.thumbnailUrl"
                  @uploaded="handleUploadData"
                />
            </div>
          </div>
          <div style="float:left;" class="col-12 mt-3 pt-3 text-left">
            <button class="btn btn-success col-12" @click="saveUpdate">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card" v-else>
    <div class="card-body">
      <LoadingComponent/>
    </div>
  </div>
</template>
<script>
import UploadFileComponent from '@/components/misc/UploadFileComponent'
import ProductServices from '@/service/ProductServices'
import LoadingComponent from '@/components/misc/LoadingComponent'
import marked from 'marked'
import _ from 'lodash'

export default {
  name: 'ProductItemDetail',
  components: { LoadingComponent, UploadFileComponent },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      mutateData: { ...this.data }
    }
  },
  watch: {
    data (val) {
      this.mutateData = { ...val }
    }
  },
  computed: {
    compiledMarkdown: function () {
      return marked(this.mutateData.description, { sanitize: true })
    }
  },
  methods: {
    update: _.debounce(function (e) {
      this.input = e.target.value
    }, 300),
    handleUploadData (e) {
      this.mutateData.thumbnailUrl = e
    },
    emitData (data) {
      this.$emit('save', data)
    },
    async saveUpdate () {
      this.isLoading = true

      const params = { ...this.mutateData }
      params.manufactureId = params.manufactureId ? params.manufactureId : ''
      params.spec = params.spec ? params.spec : ''
      params.thumbnailUrl = params.thumbnailUrl ? params.thumbnailUrl : ''
      params.description = params.description ? params.description : ''
      params.status = params.status ? params.status : 'active'
      try {
        await ProductServices.update(params, this.mutateData.id)
        this.emitData(params)
      } catch (e) {
        console.log('error', e)
      }
      this.isLoading = false
    },
    async delThumbnail () {
      this.isLoading = true

      const params = { ...this.mutateData }
      params.manufactureId = params.manufactureId ? params.manufactureId : ''
      params.spec = params.spec ? params.spec : ''
      params.thumbnailUrl = ''
      params.description = params.description ? params.description : ''
      params.status = params.status ? params.status : 'active'
      try {
        await ProductServices.update(params, this.mutateData.id)
        this.emitData(params)
        this.$router.go(0)
      } catch (e) {
        console.log('error', e)
      }
      this.isLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.textLeft {
  text-align: left;
}
</style>
