<template>
  <div class="container-fluid">
    <nav aria-label="Page navigation">
      <ul class="pagination justify-content-end d-flex">
        <li v-if="page > 0 " class="page-item cursor-pointer">
          <a class="page-link" @click="setPage(page - 1)">
            <i class="fas fa-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item cursor-pointer"
        >
          <a
            class="page-link"
          >
            {{ page }}
          </a>
        </li>
        <li class="page-item cursor-pointer">
          <a class="page-link" @click="setPage(page + 1)">
            <i class="fas fa-chevron-right"></i>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
export default {
  name: 'PaginationComponent',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
  computed: {
    query() {
      const {query} = this.$route
      return query || {}
    },
    page() {
      const {page} = this.query
      if (isNaN(Number(page))) return 1
      return page || 1
    },
  },
  methods: {
    setPage(val) {
      const {name} = this.$route
      if (name) {
        this.$router.push({
          name,
          query: {
            ...this.query,
            ...{page: val}
          }
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.page-link {
  background-color: #6f6f6f;
  margin: 0px 5px;
  border: none;
  color: white;
  border-radius: 0px;

  &.active {
    background-color: #f05023;
    border-radius: 0px;
  }

  &:hover {
    color: #f05023;
  }
}
</style>
