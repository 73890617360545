<template>
  <div
    v-if="isLoading"
    class="row">
    <div class="col-12 text-center">
      <LoadingComponent/>
    </div>
  </div>
  <div v-else class="row text-left">
    <div class="col-12 mb-3">
      <h4>
        {{ id ? 'Edit' : 'Add' }} Vehicle
      </h4>
    </div>
    <DangerComponent
      v-if="isError"
    />
    <SuccessComponent
      v-if="isSuccess"
    />
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="submit">
            <div class="form-group row">
              <div class="col-6">
                <label for="">
                  Brand
                </label>
                <input type="text" class="form-control" v-model="data.brand" placeholder="Input Brand" required>
              </div>
              <div class="col-6">
                <label for="">
                  Model
                </label>
                <input type="text" class="form-control" v-model="data.model" placeholder="Input Model" required>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-6">
                <label for="">
                  Type
                </label>
                <input type="text" class="form-control" v-model="data.type" placeholder="Input Type" required>
              </div>
            </div>
            <hr>
            <div class="form-group row">
              <div class="col-6 d-flex align-items-center justify-content-center">
                <div>
                  <img :src="image" class="img-fluid" alt="">
                  <p>
                    Front
                  </p>
                </div>
              </div>
              <div class="col-6 justify-content-center d-flex align-items-center">
                <div>
                  <img :src="image2" class="img-fluid" alt="">
                  <p>
                    Rear
                  </p>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-6">
                <label for="">
                  Tire Front Spec (Tire width in milimeters/aspect ratio, speed rating, radial construction, rim diameter in inches)
                </label>
                <input type="text" class="form-control" required v-model="data.tireFrontSpec">
              </div>
              <div class="col-6">
                <label for="">
                  Tire Rear Spec (Tire width in milimeters/aspect ratio, speed rating, radial construction, rim diameter in inches)
                </label>
                <input type="text" class="form-control" required v-model="data.tireRearSpec">
              </div>
            </div>
            <div class="form-group row">
              <div class="col-6">
                <label for="">
                  Tire Front Upsize Spec (Tire width in milimeters/aspect ratio, speed rating, radial construction, rim diameter in inches)
                </label>
                <input type="text" class="form-control" required v-model="data.tireFrontUpsizeSpec">
              </div>
              <div class="col-6">
                <label for="">
                  Tire Rear Upsize Spec (Tire width in milimeters/aspect ratio, speed rating, radial construction, rim diameter in inches)
                </label>
                <input type="text" class="form-control" required v-model="data.tireRearUpsizeSpec">
              </div>
            </div>
            <hr>
            <div class="form-group row">
              <div class="col-6">
                <label for="">
                  Break Front Spec
                </label>
                <input type="text" class="form-control" required v-model="data.brakeFrontSpec">
              </div>
              <div class="col-6">
                <label for="">
                  Break Rear Spec
                </label>
                <input type="text" class="form-control" required v-model="data.brakeRearSpec">
              </div>
            </div>
            <hr>
            <div class="form-group row">
              <div class="col-6">
                <label for="">
                  Engine Oil Spec
                </label>
                <input type="text" class="form-control" required v-model="data.engineOilSpec">
              </div>
              <div class="col-6">
                <label for="">
                  Spark Spec
                </label>
                <input type="text" class="form-control" required v-model="data.sparkSpec">
              </div>
            </div>
            <div class="form-group row">
              <div class="col-6">
                <label for="">
                  Battery Spec
                </label>
                <input type="text" class="form-control" required v-model="data.batterySpec">
              </div>
              <div class="col-6">
                <label for="">
                  Gear Oil Spec
                </label>
                <input type="text" class="form-control" v-model="data.gearOilSpec" placeholder="Gear Oil Spec" required>
              </div>
            </div>
            <div class="form-group">
              <button class="btn btn-success btn-block">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingComponent from '@/components/misc/LoadingComponent'
import DangerComponent from '@/components/misc/DangerComponent'
import SuccessComponent from '@/components/misc/SuccessComponent'
import VehiclesServices from '@/service/VehiclesServices'

const motorImg = require('@/assets/images/motor.png')
const motorImg2 = require('@/assets/images/motor2.png')
export default {
  name: 'VehicleAddScreen',
  components: { DangerComponent, LoadingComponent, SuccessComponent },
  data () {
    return {
      isLoading: false,
      isError: false,
      isSuccess: false,
      image: motorImg,
      image2: motorImg2,
      data: {
        createdTime: new Date(),
        updatedTime: new Date(),
        brand: '',
        model: '',
        type: '',
        tireFrontSpec: '',
        tireRearSpec: '',
        tireFrontUpsizeSpec: '',
        tireRearUpsizeSpec: '',
        brakeFrontSpec: '',
        brakeRearSpec: '',
        engineOilSpec: '',
        gearOilSpec: '',
        batterySpec: '',
        sparkSpec: ''
      }
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    }
  },
  methods: {
    async submit () {
      const params = this.data
      this.isLoading = true
      try {
        let res
        if (this.id) {
          params.updatedTime = new Date()
          res = await VehiclesServices.update(params, this.id)
        } else {
          res = await VehiclesServices.save(params)
        }
        await this.$router.push({
          name: 'vehicles',
          params: {
            ids: res.data.id,
            isSuccess: true
          }
        })
      } catch (e) {
        this.isError = false
      }

      this.isLoading = false
      this.isSuccess = true
    },
    async getDetail (id) {
      this.isLoading = true
      try {
        const res = await VehiclesServices.get(id)
        if (res && res.data) this.data = res.data
      } catch (e) {
        this.isError = true
      }
      this.isLoading = false
    }
  },
  mounted () {
    if (this.id) this.getDetail(this.id)
  }
}
</script>
<style lang="scss" scoped>
.img-fluid {
  max-width: 130px;
}
</style>
