<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <div v-else class="row">
      <div v-if="imageUrl" :style="{ width: `35px` }" class="mx-3 py-0 align-items-center mb-3">
        <a :href="imageUrl" target="_blank" :style="{ backgroundImage: `url(${imageUrl})` }" class="btn btn-secondary thumbnail btn-block text-white"> </a>
      </div>
      <div v-if="isEdit" class="py-0" :class="[imageUrl ? 'col-10 mr-3' : 'col-10 mx-3']">
        <input @change="uploadFile" ref="inputFile" type="file" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" />
        <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
        <small v-if="isError" class="text-danger">
          {{ message }}
        </small>
      </div>
    </div>
  </div>
</template>
<script>
  import LoadingComponent from '@/components/misc/LoadingComponent'
  import FileServices from '@/service/FileServices'

  export default {
    name: 'UploadFileComponent',
    components: { LoadingComponent },
    props: {
      isEdit: {
        type: Boolean,
        default: true
      },
      url: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isLoading: false,
        imageUrl: '',
        isError: false,
        message: 'File size exceed 200Kb'
      }
    },
    methods: {
      async uploadFile() {
        this.isLoading = true
        const input = this.$refs.inputFile.files[0]
        if (input.size && input.size <= 500000) {
          const params = {
            file: input
          }
          try {
            const res = await FileServices.save(params)
            if (res) {
              const { files } = res.data
              if (files.length > 0 && files[0]) {
                const imageName = files[0].url
                this.imageUrl = await this.getFile(imageName)
                this.$emit('uploaded', this.imageUrl)
              }
            }
          } catch (e) {
            console.log('upload image error', e, e.response)
          }
        } else {
          this.isError = true
          this.message = 'File size exceed 200kb'
        }
        this.isLoading = false
      },
      getFile(str) {
        return FileServices.get(str)
      }
    },
    watch: {
      url(val) {
        this.imageUrl = val
      }
    },
    mounted() {
      if (this.url) this.imageUrl = this.url
    }
  }
</script>
<style lang="scss" scoped>
  .thumbnail {
    background-size: cover;
    background-position: center;
    width: 35px;
    height: 35px;
  }
</style>
