<template>
  <div class="row">
    <PageHeaderComponent
      :label="`List of Region`"
      :button-label="`Add Region`"
      @btn-click="clickAction"
    />
    <div class="col-12">
      <v-data-table
        :headers="headers"
        :items="region"
        :items-per-page="15"
        class="elevation-1"
        :search="search"
      >
        <template v-slot:top>
          <div class="row">
            <div class="col-6 ml-auto">
              <input type="text" v-model="search" class="form-control" placeholder="search">
            </div>
          </div>
        </template>
        <template v-slot:item="{ item }">
          <RegionItemComponent
            :value="item"
            @save-data="updateData($event)"
            @delete="removeData($event)"
          />
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import PageHeaderComponent from '@/components/misc/PageHeaderComponent'
import RegionItemComponent from '@/views/region/component/RegionItemComponent'
import RegionServices from '@/service/RegionServices'

export default {
  name: 'RegionScreen',
  components: { RegionItemComponent, PageHeaderComponent },
  data () {
    return {
      isLoading: true,
      region: [],
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id'
        },
        {
          text: 'Region',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Longitude',
          align: 'start',
          sortable: true,
          value: 'longitude'
        },
        {
          text: 'Latitude',
          align: 'start',
          sortable: true,
          value: 'latitude'
        },
        {
          text: 'Status',
          align: 'start',
          sortable: true,
          value: 'status'
        },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      search: ''
    }
  },
  methods: {
    clickAction () {
      const obj = {
        id: '',
        name: '',
        isEditable: true,
        index: this.region.length
      }
      this.region.unshift(obj)
    },
    updateData (data) {
      this.region.map((v) => {
        console.log(data, v)
        if (data.index === v.index) {
          v = data
          v.isEditable = false
          return v
        }
      })
    },
    async getData () {
      this.isLoading = true
      try {
        const res = await RegionServices.getAll({})
        if (res && res.data) {
          this.region = [...this.region, ...res.data]
          this.region.map((v, k) => {
            v.index = k + 1
          })
        }
      } catch (e) {
        console.log(e)
      }
      this.isLoading = false
    },
    removeData (id) {
      this.region = this.region.filter((v) => {
        return v.id !== id
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style lang="scss" scoped></style>
