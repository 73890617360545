/*eslint no-unused-vars: "error"*/
<template>
  <tr>
    <td>
      <input
          v-if="isEditable"
          type="text" v-model="mutableData.name" class="form-control">
      <span v-else>
        {{ mutableData.name }}
      </span>
    </td>
    <td>
      <input
          v-if="isEditable"
          type="email" v-model="mutableData.email" class="form-control">
      <span v-else>
        {{ mutableData.email }}
      </span>
    </td>
    <td>
      <input
          v-if="isEditable"
          type="number" v-model="mutableData.mobile" class="form-control">
      <span v-else>
        {{ mutableData.mobile }}
      </span>
    </td>
    <td>
      {{ mutableData.branchCode }}
    </td>
    <td>
      {{ renderStore(mutableData) }}
    </td>
    <td>
      {{ $helper.dateFormatter(mutableData.updatedTime) }}
    </td>
    <td nowrap>
      <div
          v-tooltip="'Edit'"
          @click="editData"
          v-if="!isEditable"
          class="btn btn-secondary btn-sm mr-1">
        <i class="fas fa-edit"></i>
      </div>
      <div
          v-tooltip="'Save'"
          @click="save"
          class="btn btn-primary btn-sm mr-2"
          v-if="isEditable">
        <i class="fas fa-save"></i>
      </div>
      <div
          v-tooltip="'Cancel'"
          @click="editData"
          v-if="isEditable"
          class="btn btn-danger btn-sm mr-2">
        <i class="fas fa-times"></i>
      </div>
      <div
        v-tooltip="'Update password'"
        @click="changePassword"
        class="btn btn-secondary mr-2 btn-sm">
        <i class="fas fa-unlock"></i>
      </div>
    </td>
    <AdminUpdatePasswordModalComponent
      :is-show="isShow"
      :data="mutableData"
      @showNotif="showNotif"
      @close="closeModal"
    />
  </tr>
</template>
<script>
import UserServices from '@/service/UserServices'
import AdminUpdatePasswordModalComponent from '@/views/admin/components/AdminUpdatePasswordModalComponent'
import moment from 'moment'
export default {
  name: 'AdminItemComponent',
  components: { AdminUpdatePasswordModalComponent },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isShow: false,
      mutableData: {
        activeTime: '',
        authorizationCode: '',
        authorizationTime: '',
        branchCode: '',
        citizenId: '',
        class: '',
        configs: {},
        createdTime: '',
        email: '',
        employeeId: '',
        gender: '',
        licenseId: '',
        membershipId: '',
        mobile: '',
        name: '',
        realm: '',
        redeemCode: '',
        roles: [],
        type: '',
        updatedTime: ''
      },
      role: ''
    }
  },
  computed: {
    isEditable () {
      return this.mutableData.isEditable
    }
  },
  methods: {
    showNotif () {
      this.$emit('showAlert', { message: 'Password Updated' })
    },
    closeModal () {
      this.isShow = false
    },
    changePassword () {
      this.isShow = true
    },
    editData () {
      this.mutableData = {
        ...this.mutableData,
        ...{ isEditable: !this.mutableData.isEditable }
      }
    },
    renderStore (data) {
      const { roles } = data
      console.log(data)
      if (roles && roles[0] === 'admin') {
        return 'Admin'
      } else if (roles && roles[0] === 'operator') {
        return 'Operator'
      } else {
        return 'Merchant'
      }
    },
    async remove () {
      await UserServices.delete(this.data.id)
      this.$emit('delete', this.data.id)
    },
    async save () {
      const { name, email, mobile } = this.mutableData
      if (!name && !email && !mobile) {
        alert('Name, Email and Mobile is mandatory')
      } else {
        try {
          const params = {
            id: this.mutableData.id,
            name: this.mutableData.name,
            email: this.mutableData.email,
            mobile: this.mutableData.mobile
          }
          delete params.isEditable

          params.birthDate = moment()

          if (this.role) {
            params.roles = [this.role]
          }

          Object.keys(params).forEach((v) => {
            if (!params[v]) params[v] = ''
          })

          if (params.id) {
            delete params.id
            await UserServices.update(params, this.data.id)
          }
          this.mutableData.isEditable = false
          this.$emit('updated', this.data.id)
        } catch (e) {
          console.log('error', e)
        }
      }
    }
  },
  watch: {
    data (val) {
      this.mutableData = val
    }
  },
  mounted () {
    if (this.data) {
      this.mutableData = this.data
      this.role = this.mutableData.roles[0]
    }
  }
}
</script>
<style lang="scss" scoped></style>
