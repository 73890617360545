<template>
  <div class="card mb-3">
    <div class="card-header bg-danger text-white">
      Devices
    </div>
    <div class="card-body" v-if="isLoading">
      <LoadingComponent/>
    </div>
    <div
      v-else
      style="max-height: 300px; overflow-y: scroll"
      class="card-body">
      <table
        class="table table-striped">
        <tr>
          <th>
            Device Id
          </th>
          <th>
            Name
          </th>
          <th>
            Os
          </th>
          <th>
            Version
          </th>
          <th>
            Updated Time
          </th>
          <th>
            Status
          </th>
          <th>
              Action
          </th>
        </tr>
        <tr :key="val.id" v-for="val in data">
          <td>
            {{ val.deviceId }}
          </td>
          <td>
            {{ val.name }}
          </td>
          <td>
            {{ val.os }}
          </td>
          <td>
            {{ val.version }}
          </td>
          <td>
            {{ $helper.dateFormatter(val.updatedTime) }}
          </td>
          <td>
            {{ val.status }}
          </td>
          <td>
            <button v-if="val.status ==='active'" v-tooltip="'Set Inactive'" @click="setInactive(val.userId, val.id)" class="btn btn-danger">Set Inactive</button>
            <button v-if="val.status ==='inactive'" v-tooltip="'Set Active'" @click="setActive(val.userId, val.id)" class="btn btn-success">Set Active</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import UserServices from '@/service/UserServices'
import LoadingComponent from '@/components/misc/LoadingComponent'

export default {
  name: 'UserDeviceComponent',
  components: { LoadingComponent },
  data () {
    return {
      isLoading: true,
      data: []
    }
  },
  methods: {
    async get () {
      this.isLoading = true

      try {
        const res = await UserServices.device(this.$route.params.id)
        if (res) this.data = res.data
      } catch (e) {
        console.log('error', e, e.response)
      }

      this.isLoading = false
    },
    async setActive (userid, id) {
      this.isLoading = true
      const params = {
        status: 'active'
      }
      try {
        await UserServices.setActiveDevice(params, userid, id)
        this.$emit('confirm', { message: 'Device Set To Active' })
      } catch (e) {
        console.log(e)
      }
      this.$router.go(0)
    },
    async setInactive (userid, id) {
      this.isLoading = true
      const params = {
        status: 'inactive'
      }
      try {
        await UserServices.setInactiveDevice(params, userid, id)
        this.$emit('confirm', { message: 'Device Set To Inactive' })
      } catch (e) {
        console.log(e)
      }
      this.$router.go(0)
    }
  },
  mounted () {
    this.get()
  }
}
</script>
<style lang="scss" scoped></style>
