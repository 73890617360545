<template>
  <div class="row">
    <PageHeaderComponent
      :label="`List of Store`"
      :button-label="`Add Store`"
      @btn-click="addStore"
      v-if="isAdmin"
    />
    <div class="col-12">
      <LoadingComponent v-if="isLoading"/>
      <v-data-table
        v-else
        :headers="headers"
        :items="data"
        :items-per-page="15"
        class="elevation-1"
        :search="search"
      >
        <template v-slot:top>
          <div class="col-12">
            <div class="row">
              <div class="col-6 ml-auto">
                <input type="text" v-model="search" class="form-control" placeholder="search">
              </div>
            </div>
          </div>
        </template>
        <template v-slot:item="{ item }">
          <StoreItemComponent :data="item"/>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import PageHeaderComponent from '@/components/misc/PageHeaderComponent'
import LoadingComponent from '@/components/misc/LoadingComponent'
import StoreItemComponent from '@/views/store/StoreItemComponent'
import MerchantServices from '@/service/MerchantServices'

export default {
  name: 'StoreScreen',
  components: { StoreItemComponent, LoadingComponent, PageHeaderComponent },
  data () {
    return {
      isLoading: false,
      search: '',
      data: [],
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id'
        },
        {
          text: 'Region ID',
          align: 'start',
          sortable: true,
          value: 'regionId'
        },
        {
          text: 'Branch Type',
          align: 'start',
          sortable: true,
          value: 'branchType'
        },
        {
          text: 'Store Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Status',
          align: 'start',
          sortable: true,
          value: 'status'
        },
        { text: 'Phone', value: 'phone' },
        { text: 'Image', value: 'imageUrl' },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    }
  },
  methods: {
    addStore () {
      this.$router.push({
        name: 'add-store'
      })
    },
    async getData () {
      this.isLoading = true
      try {
        const res = await MerchantServices.getAll({})
        if (res && res.data) this.data = res.data
      } catch (e) {
        console.log('get all store failed', e, e.response)
      }
      this.isLoading = false
    }
  },
  mounted () {
    this.getData()
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isMerchant', 'isOperator'])
  }
}
</script>
<style lang="scss" scoped></style>
