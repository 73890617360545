<template>
  <div class="col-12">
    <LoadingComponent v-if="isLoading" />
    <TransactionTableComponent
      v-else
      :data="data"
    />
  </div>
</template>
<script>
import LoadingComponent from '@/components/misc/LoadingComponent'
import TransactionTableComponent from '@/views/transaction/component/TransactionTableComponent'
import OrderServices from '@/service/OrderServices'
export default {
  name: 'UserTransactionComponent',
  components: { TransactionTableComponent, LoadingComponent },
  data () {
    return {
      isLoading: true,
      data: []
    }
  },
  methods: {
    async getData () {
      this.isLoading = true

      const filter = {
        include: [
          {
            relation: 'items',
            scope: {
              order: 'createdTime ASC',
              include: [{
                relation: 'product'
              }]
            }
          },
          {
            relation: 'user'
          },
          {
            relation: 'merchant'
          }
        ],
        where: {
          userId: this.$route.params.id
        },
        order: 'createdTime DESC'
      }

      try {
        const res = await OrderServices.getAll(`filter=${JSON.stringify(filter)}`)
        if (res) this.data = res.data
      } catch (e) {
        console.log('error', e)
      }

      this.isLoading = false
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style lang="scss" scoped></style>
