import axios from 'axios'
import { setHeaderToken } from './auth'
import router from '../src/router'

const esc = encodeURIComponent

class Http {
    get = (url, data) => {
        let query = null

        if (data && data.filter) {
            query = Object.keys(data)
                .map(k => `${esc(k)}=${esc(data[k])}`)
                .join('&')
        } else if (data) {
            query = data
        }
        if (query !== null) {
            url = `${url}?${query}`
        }

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }

        setHeaderToken()

        const res = axios({
            method: 'get',
            url,
            headers
        })

        res.catch(err => {
            if (err.response.status === 401) router.push('/logout')
            return false
        })

        return res
    };

    post = (url, data) => {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }

        setHeaderToken()

        const formData = new FormData()

        if (data.file) {
            headers['Content-Type'] = 'multipart/form-data'
            formData.append('file', data.file)
            data = formData
        } else {
            data = JSON.stringify(data)
        }

        const res = axios({
            method: 'post',
            url,
            headers,
            data
        })

        res.catch(err => {
            if (err.response.status === 401) router.push('/logout')
            return false
        })

        return res
    };

    patch = (url, data) => {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }

        setHeaderToken()

        delete data.accessToken
        data = JSON.stringify(data)
        const res = axios({
            method: 'patch',
            url,
            headers,
            data
        })

        res.catch(err => {
            if (err.response.status === 401) router.push('/logout')
            return false
        })

        return res
    };

    put = (url, data) => {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }

        setHeaderToken()

        delete data.accessToken
        data = JSON.stringify(data)
        const res = axios({
            method: 'put',
            url,
            headers,
            data
        })

        res.catch(err => {
            if (err.response.status === 401) router.push('/logout')
            return false
        })

        return res
    };

    delete = (url, data) => {
        const query = Object.keys(data)
            .map(k => `${esc(k)}=${esc(data[k])}`)
            .join('&')

        url = `${url}?${query}`

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }

        setHeaderToken()

        const res = axios({
            method: 'delete',
            url,
            headers
        })

        res.catch(err => {
            if (err.response.status === 401) router.push('/logout')
            return false
        })

        return res
    };
}

export default new Http()