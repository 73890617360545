<template>
  <tr>
    <td>
      {{ data.id }}
    </td>
    <td>
      <input
        v-if="isEditable"
        type="text" v-model="data.name" class="form-control form-control-sm">
      <span v-else> {{ data.name}}</span>
      <small
        v-if="isError"
        class="text-danger"> This field is required</small>
    </td>
    <td>
      <input
        v-if="isEditable"
        type="text" v-model="data.longitude" class="form-control form-control-sm">
      <span v-else> {{ data.longitude}}</span>
      <small
        v-if="isError"
        class="text-danger"> This field is required</small>
    </td>
    <td>
      <input
          v-if="isEditable"
          type="text" v-model="data.latitude" class="form-control form-control-sm">
        <span v-else> {{ data.latitude}}</span>
        <small
          v-if="isError"
          class="text-danger"> This field is required</small>
    </td>
    <td>
        {{ data.status }}
    </td>
    <td>
      <LoadingComponent v-if="isLoading"/>
      <div v-else>
        <button
          v-tooltip="'Save'"
          v-if="!data.id && isEditable"
          @click="save"
          class="btn btn-primary btn-sm mr-2" alt="save">
          <i class="fas fa-save"></i>
        </button>
        <button
          v-tooltip="'Save'"
          v-else-if="data.id && isEditable"
          @click="update"
          class="btn btn-sm btn-success mr-2">
          <i class="fas fa-save"></i>
        </button>
        <button
          v-tooltip="'Edit'"
          @click="edit(true)"
          v-else class="btn btn-secondary mr-2 btn-sm">
          <i class="fas fa-edit"></i>
        </button>
        <button
          v-tooltip="'Delete'"
          v-if="data.id && isAdmin"
          @click="remove"
          class="btn btn-sm btn-danger">
          <i class="fas fa-trash"></i>
        </button>
        <button v-if="isEditable" class="btn btn-danger btn-sm" @click="cancelEdit">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </td>
  </tr>
</template>
<script>
import { mapGetters } from 'vuex'
import LoadingComponent from '@/components/misc/LoadingComponent'
import RegionServices from '@/service/RegionServices'

export default {
  name: 'RegionItemComponent',
  components: { LoadingComponent },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          id: '',
          name: '',
          longitude: '',
          latitude: ''
        }
      }
    }
  },
  computed: {
    isEditable () {
      return this.data.isEditable
    },
    ...mapGetters('auth', ['isAdmin', 'isMerchant', 'isOperator'])
  },
  data () {
    return {
      isError: false,
      isLoading: false,
      data: {
        id: '',
        name: ''
      }
    }
  },
  watch: {
    value (val) {
      this.data = val
    }
  },
  methods: {
    cancelEdit () {
      this.data = { ...this.data, ...{ isEditable: false } }
    },
    edit (status) {
      this.data = { ...this.data, ...{ isEditable: status } }
    },
    handlingData (data) {
      this.data = { ...this.data, ...data, ...{ isEditable: false } }
      this.$emit('save-data', this.data)
    },
    async save () {
      this.isError = false
      this.isLoading = true
      if (!this.data.name) {
        this.isError = true
      } else {
        try {
          const params = {
            name: this.data.name
          }

          delete params.id
          delete params.isEditable
          delete params.index

          const res = await RegionServices.save(params)
          if (res && res.data) {
            this.handlingData(res.data)
          }
        } catch (e) {
          console.log(e)
          this.isError = true
        }
      }

      this.isLoading = false
    },
    async update () {
      this.isLoading = true
      if (!this.data.name) {
        this.isError = true
      } else {
        try {
          const params = {
            name: this.data.name,
            longitude: parseFloat(this.data.longitude),
            latitude: parseFloat(this.data.latitude)
          }
          delete params.isEditable
          delete params.index
          await RegionServices.update(params, this.data.id)
          this.handlingData(this.data)
        } catch (e) {
          console.log(e)
        }
      }
      this.isLoading = false
    },
    async remove () {
      try {
        await RegionServices.delete(this.data.id)
        this.$emit('delete', this.data.id)
      } catch (e) {
        console.log(e)
      }
    }
  },
  mounted () {
    this.data = this.value
  }
}
</script>
<style lang="scss" scoped></style>
