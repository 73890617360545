<template>
  <div class="card textLeft">
    <div class="card-header">
      <div class="row">
        <div class="col-6">
          <b>Order Id</b> {{ data.id }} <br />
          <b>Invoice</b> {{ data.invoiceNumber }}
        </div>
        <div class="col-6 text-right">
          Status: <b>{{ data.status }}</b
          ><br />
          {{ $helper.dateFormatter(data.createdTime) }} (Visit:
          {{
            data.deliveryDate ? $helper.dateFormatter(data.deliveryDate) : '-'
          }})
        </div>
      </div>
    </div>
    <div class="card-body">
      <div v-if="data.serviceCode === 'STO'" class="row itemHeader">
        <div><b>Service di toko</b></div>
      </div>
      <div v-else-if="data.serviceCode === 'SDA'" class="row itemHeader">
        <div><b>Service di mana aja</b></div>
      </div>
      <div class="row">
        <div class="col-lg-5 col-md-5 col-sm-12 col-12 mb-3 border-right">
          <div v-if="data.merchantId" class="row">
            <div class="col-5">
              <div
                :style="{ backgroundImage: `url(${assetUrl})` }"
                class="standard-thumbnail border"
              ></div>
            </div>
            <div class="col-7">
              <div>
                {{ merchant.name }}
              </div>
              <div>Address: {{ merchant.address }}</div>
              <div>Phone: {{ merchant.phoneNumber }}</div>
            </div>
          </div>
          <div v-else-if="data.regionId" class="row">
            <div class="col-7">
              Region: {{ data.regionId }} <br />
              Store hasn't choose yet by customer
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-md-7 col-sm-12 col-12">
          <div class="row">
            <div class="col-7  border-right">
              <div>Customer</div>
              <div>Name: {{ user.name }}</div>
              <div>Phone: {{ user.mobile }}</div>
              <div>Email: {{ user.email }}</div>
              <div v-if="data.serviceCode === 'SDA' && data.address">
                Address: {{ data.address }}
              </div>
              <div v-else>Address: {{ user.address }}</div>
            </div>
            <div
              v-if="data.status !== 'unpaid' && data.payments"
              class="col-5 d-flex align-items-left justify-content-left"
            >
              Payment
              <br />
              {{ data.payments[0].payment }} ({{
                data.payments[0].status
              }})<br />
              Order ID: {{ data.payments[0].id }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="items.length" class="container-items">
        <div class="col-12 itemHeader"><b>Purchased items</b></div>
        <OrderProductComponent
          v-for="(val, key) in dataItem"
          :key="key"
          :data="val"
        />
        <div
          @click="isShowAll = !isShowAll"
          v-if="items.length > 1"
          class="col-12 text-center cursor-pointer"
        >
          {{ renderShowMoreText }}
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <hr />
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-start">
              <button
                @click="complete()"
                v-if="
                  data.status === 'confirmed' &&
                    (isMerchant || isAdmin || isOperator)
                "
                class="btn btn-primary mr-3 btn-lg"
              >
                Finish
              </button>
              <button
                v-if="
                  data.status === 'pending' &&
                    (isAdmin || isOperator) &&
                    $route.name === 'dashboard'
                "
                class="btn btn-success mr-3 btn-lg"
              >
                <LoadingComponent v-if="isLoading" />
                <span @click="confirm" v-else>
                  Confirm
                </span>
              </button>
              <button
                @click="rejectTransaction()"
                v-if="
                  data.status === 'pending' &&
                    (isAdmin || isOperator) &&
                    $route.name === 'dashboard'
                "
                class="btn btn-danger mr-3 btn-lg"
              >
                Cancel Transaction
              </button>
            </div>
            <div class="col-3 d-flex align-items-center justify-content-start">
              <router-link
                :to="{
                  name: 'downloadinvoice',
                  params: {
                    id: data.id
                  }
                }"
                target="_blank"
              >
                Download Invoice
              </router-link>
            </div>
            <div
              class="col-3 d-flex align-items-center justify-content-end text-right"
            >
              <div>
                <div>
                  Sub Total: IDR {{ $helper.numberFormatter(subTotalPrice) }}
                </div>
                <div>
                  Discount: IDR {{ $helper.numberFormatter(discountPrice) }}
                </div>
                <div class="font-weight-bold font-total">
                  Total: IDR {{ $helper.numberFormatter(totalPrice) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import OrderProductComponent from '@/views/dashboard/component/OrderProductComponent'
  import OrderServices from '@/service/OrderServices'
  import LoadingComponent from '@/components/misc/LoadingComponent'

  const image = require('@/assets/images/image-placeholder.jpeg')
  export default {
    name: 'NewOrderItemComponent',
    components: { LoadingComponent, OrderProductComponent },
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    computed: {
      ...mapState('auth', {
        loginUser: 'user'
      }),
      isAdmin() {
        return (
          this.loginUser &&
          this.loginUser.realm === 'admin' &&
          this.loginUser.roles.indexOf('admin') !== -1
        )
      },
      isOperator() {
        return (
          this.loginUser &&
          this.loginUser.realm === 'admin' &&
          this.loginUser.roles.indexOf('operator') !== -1
        )
      },
      isMember() {
        return this.loginUser && this.loginUser.realm === 'member'
      },
      isMerchant() {
        return (
          this.loginUser &&
          this.loginUser.realm === 'admin' &&
          this.loginUser.roles.indexOf('merchant') !== -1
        )
      },
      merchant() {
        return this.data.merchant ? this.data.merchant : []
      },
      user() {
        return this.data.user ? this.data.user : []
      },
      items() {
        return this.data.items ? this.data.items : []
      },
      dataItem() {
        let data = []
        if (this.items && this.items.length) {
          if (this.items[0].product.category === 'promosi-barang-promo') {
            data = [this.items[1]]
          } else {
            data = [this.items[0]]
          }
        }
        if (this.isShowAll) data = this.data.items
        return data
      },
      discountPrice() {
        let disc = 0
        if (this.items.length) {
          this.items.map(v => {
            if (v.product.category === 'promosi-barang-promo') {
              disc += v.price * v.quantity
            }
          })
        }
        return disc - disc - disc
      },
      subTotalPrice() {
        let total = 0
        if (this.items.length) {
          this.items.map(v => {
            if (v.product.category !== 'promosi-barang-promo') {
              total += v.price * v.quantity
            }
          })
        }
        return total
      },
      totalPrice() {
        return this.subTotalPrice - this.discountPrice
      },
      renderShowMoreText() {
        if (!this.items.length) return ''
        if (this.isShowAll) return 'See Less item'
        return 'see more items'
      },
      assetUrl() {
        if (this.data.merchant.imageUrl) {
          return this.data.merchant.imageUrl
            ? this.data.merchant.imageUrl
            : image
        }
        return image
      }
    },
    data() {
      return {
        image,
        isShowAll: false,
        isLoading: false
      }
    },
    methods: {
      async rejectTransaction() {
        this.isLoading = true
        try {
          await OrderServices.reject(this.data.id)
          this.$emit('confirm', { message: 'Order cancelled Successfully' })
        } catch (e) {
          console.log(e)
        }
        this.isLoading = false
        this.showNotif = true
      },
      async confirm() {
        this.isLoading = true
        try {
          await OrderServices.confirm(this.data.id)
          this.$emit('confirm', { message: 'Order confirmed Successfully' })
        } catch (e) {
          console.log('confirm error', e, e.response)
        }
        this.isLoading = false
      },
      async complete() {
        this.isLoading = true
        try {
          await OrderServices.complete(this.data.id)
          this.data.status = 'completed'
          this.$emit('complete', { message: 'Order completed Successfully' })
        } catch (e) {
          console.log('confirm error', e, e.response)
        }
        this.isLoading = false
      }
    }
  }
</script>
<style lang="scss" scoped>
  .card {
  }

  .service-code {
    font-size: 1.3em;
  }
  .itemHeader {
    border-bottom: 1px solid #eee;
    font-size: 1.3em;
    padding: 10px;
  }

  .card-header {
    background-color: #666;
    color: #fff;
    font-size: 1.2em;
    padding: 5px 10px;
  }

  .container-items {
  }

  .textLeft {
    text-align: left;
  }
  .font-total {
    font-size: 1.2em;
  }

  a {
    color: green;
  }

  a:hover {
    color: green;
    font-weight: bold;
  }
</style>
