<template>
  <tr>
    <td>
        {{ mutableData.membershipId }}
    </td>
    <td>
        {{ mutableData.mobile}}
    </td>
    <td>
        {{ mutableData.email }}
    </td>
    <td>
        {{ mutableData.name }}
    </td>
    <td>
      {{ $helper.dateFormatterMember(mutableData.createdTime)}}
    </td>
    <td nowrap>
      <router-link :to="{
        name: 'member-detail',
        params: {
          id: data.id
        }
      }" v-tooltip="'View Detail'" class="btn btn-secondary btn-sm mr-1">
        <i class="fas fa-eye"></i>
      </router-link>
      <router-link :to="{
        name: 'edit-member',
        params: {
          id: data.id
        }
      }"
          v-tooltip="'Edit Member'" v-if="isAdmin" class="btn btn-secondary btn-sm mr-1">
        <i class="fas fa-edit"></i>
      </router-link>
      <button v-tooltip="'Reset Password'" @click="reset" class="btn btn-secondary btn-sm"><i class="fas fa-key"></i></button>
    </td>
    <LoadingComponent v-if="isLoading" />
  </tr>
</template>
<script>
import LoadingComponent from '@/components/misc/LoadingComponent'
import { mapGetters } from 'vuex'
import UserService from '@/service/UserServices'
export default {
  name: 'MemberItemComponent',
  components: { LoadingComponent },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      mutableData: {
        id: '',
        activeTime: '',
        authorizationCode: null,
        authorizationTime: null,
        branchCode: null,
        citizenId: null,
        class: '',
        configs: {},
        createdTime: '',
        email: '',
        employeeId: null,
        gender: '',
        licenseId: null,
        membershipId: null,
        mobile: '',
        name: '',
        realm: '',
        redeemCode: null,
        roles: [],
        type: null,
        updatedTime: ''
      }
    }
  },
  computed: {
    isEditable () {
      return this.mutableData.isEditable
    },
    ...mapGetters('auth', ['isAdmin', 'isMerchant', 'isOperator'])
  },
  methods: {
    editData () {
      this.mutableData = {
        ...this.mutableData,
        ...{ isEditable: !this.mutableData.isEditable }
      }
    },
    renderGender (data) {
      if (data.gender === 'perempuan') {
        return 'female'
      }
      return 'male'
    },
    async reset () {
      this.isLoading = true
      try {
        await UserService.ResetPassword(this.mutableData.id)
        this.$emit('confirm', { message: 'Password reset success' })
      } catch (e) {
        console.log(e)
      }
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    }
  },
  watch: {
    data (val) {
      this.mutableData = val
    }
  },
  mounted () {
    if (this.data) this.mutableData = this.data
  }
}
</script>
<style lang="scss" scoped></style>
