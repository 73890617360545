<template>
  <div class="row">
    <PageHeaderComponent
      :label="`List of Admin`"
      :button-label="`Add New Admin`"
      @btn-click="addAdmin"
    />
    <DashboardNotificationComponent
      :is-show="isShow"
      :message="message"
      @close="closeNotif"
      class="col-12"
    />
    <AdminSearchComponent />
    <div class="col-12">
      <LoadingComponent v-if="isLoading" />
      <v-data-table
        v-else
        :headers="headers"
        :items="data"
        :items-per-page="15"
        class="elevation-1"
        :search="search"
      >
        <template v-slot:item="{ item }">
          <AdminItemComponent :data="item"
                              @showAlert="showAlert"
                              @updated="handleUpdate"
                              @delete="handleDelete"/>
        </template>
      </v-data-table>
    </div>
    <AddAdminModalComponent
      :is-show="isAddModal"
      @showAlert="showAlert"
      @close="handleClose"
    />
  </div>
</template>
<script>
import PageHeaderComponent from '@/components/misc/PageHeaderComponent'
import AdminSearchComponent from '@/views/admin/AdminSearchComponent'
import LoadingComponent from '@/components/misc/LoadingComponent'
import AdminItemComponent from '@/views/admin/AdminItemComponent'
import UserServices from '@/service/UserServices'
import AddAdminModalComponent from '@/views/admin/components/AddAdminModalComponent'
import DashboardNotificationComponent from '@/views/dashboard/component/DashboardNotificationComponent'
export default {
  name: 'ListAdminScreen',
  components: { AddAdminModalComponent, AdminItemComponent, LoadingComponent, AdminSearchComponent, PageHeaderComponent, DashboardNotificationComponent },
  data () {
    return {
      isLoading: true,
      isShow: false,
      search: '',
      data: [],
      message: '',
      isAddModal: false,
      isSuccessPass: false,
      headers: [
        {
          text: 'Admin Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'phone' },
        { text: 'Store', value: 'phone' },
        { text: 'Role', value: 'store' },
        { text: 'Updated Date', value: 'updatedDate' },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    }
  },
  watch: {
    '$route.query': function (val) {
      this.getAll()
    }
  },
  computed: {
    query () {
      return this.$route.query
    }
  },
  methods: {
    showAlert (e) {
      this.message = e.message
      this.isShow = true
    },
    closeNotif () {
      this.isShow = false
    },
    handleDelete (e) {
      this.data = this.data.filter((v) => {
        return v.id !== e
      })
    },
    handleUpdate (e) {
      this.data.map((v) => {
        if (v.id === e) {
          v.isEditable = false
        }
      })
    },
    async getAll () {
      this.isLoading = true
      try {
        const params = {
          filter: {
            where: {
              realm: 'admin'
            }
          }
        }

        const { search } = this.query

        if (search) {
          params.filter.where = {
            ...params.filter.where,
            ...{
              and: [
                {
                  or: [
                    { id: search },
                    { email: search },
                    { mobile: search },
                    { name: search },
                    { branchCode: search }
                  ]
                }
              ]
            }
          }
        }

        params.filter = JSON.stringify(params.filter)

        const res = await UserServices.getAll(params)
        if (res && res.data) this.data = res.data

        this.data = this.data.map((v) => {
          v.isEditable = false
          return v
        })
      } catch (e) {
        console.log('get data user', e, e.response)
      }
      this.isLoading = false
    },
    addAdmin () {
      this.isAddModal = true
    },
    handleClose () {
      this.isAddModal = false
      this.getAll()
    }
  },
  mounted () {
    this.getAll()
  }
}
</script>
<style lang="scss" scoped></style>
