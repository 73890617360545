<template>
  <tr>
    <td>
      {{ data.userId }}
    </td>
    <td>
      {{ data.name }}
    </td>
    <td>
      {{ data.code }}
    </td>
    <td>
      {{ $helper.dateFormatter(data.createdTime)}}
    </td>
    <td>
      {{ $helper.dateFormatter(data.expiredTime)}}
    </td>
    <td>
      {{ data.status }}
    </td>
    <td>
      {{ data.notes }}
    </td>
  </tr>
</template>
<script>
export default {
  name: 'VoucherItemcomponent',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped></style>
