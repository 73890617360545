<template>
  <div class="row">
    <PageHeaderComponent :label="`List of Transaction`" />
    <TransactionSearchComponent />
    <div v-if="!isSearch" class="col-12">
      <ul class="nav nav-pills nav-justified">
        <li @click="navigate(val.status)" v-for="val in tabs" :key="val.label" class="nav-item">
          <a :class="[val.status === status ? 'activeTab' : 'tab-bg']" class="nav-link" href="#">
            {{ val.label }}
          </a>
        </li>
      </ul>
    </div>
    <div class="col-12">
      <LoadingComponent v-if="isLoading" />
      <TransactionTableComponent v-else :data="data" />
    </div>
    <div class="col-12">
      <PaginationComponent/>
    </div>
  </div>
</template>
<script>
  import PageHeaderComponent from '@/components/misc/PageHeaderComponent'
  import LoadingComponent from '@/components/misc/LoadingComponent'
  import TransactionTableComponent from '@/views/transaction/component/TransactionTableComponent'
  import OrderServices from '@/service/OrderServices'
  import TransactionSearchComponent from '@/views/transaction/component/TransactionSearchComponent'
  import PaginationComponent from "@/components/misc/PaginationComponent";
  // import moment from 'moment'

  export default {
    name: 'TransactionScreen',
    components: { TransactionSearchComponent, PaginationComponent, TransactionTableComponent, LoadingComponent, PageHeaderComponent },
    data() {
      return {
        isLoading: true,
        data: [],
        status: false,
        tabs: [
          {
            status: 'unpaid',
            label: 'Waiting For Payment'
          },
          {
            status: 'pending',
            label: 'Pending Approval'
          },
          {
            status: 'confirmed',
            label: 'On Process'
          },
          {
            status: 'canceled',
            label: 'Cancelled/Rejected'
          },
          {
            status: 'completed',
            label: 'Completed Orders'
          }
        ]
      }
    },
    computed: {
      query() {
        return this.$route.query
      },
      isSearch() {
        const { invoice, mobile, orderId, store } = this.query
        return invoice || mobile || orderId || store
      }
    },
    watch: {
      '$route.query'(val) {
        const { status } = val
        if (status) {
          this.status = status
        } else {
          this.status = 'unpaid'
        }
        this.get()
        const {page} = val
        if (page && !isNaN(Number(page)) && page > 0) 
        { this.get(page) } else {
          this.get(page)
        }
        
        
      }
    },
    methods: {
      navigate(status) {
        this.$router
          .push({
            name: 'transaction',
            query: {
              ...this.query,
              ...{ status }
            }
          })
          .catch()
      },
      async get(page = 1) {
        const limit = 5
        const filter = {
          include: [
            {
              relation: 'items',
              scope: {
                order: 'createdTime ASC',
                include: [
                  {
                    relation: 'product'
                  }
                ]
              }
            },
            {
              relation: 'user'
            },
            {
              relation: 'merchant'
            },
            {
              relation: 'payments',
              scope: {
                order: 'createdTime DESC'
              }
            }
          ],
          where: {
            status: { inq: ['unpaid', 'inpayment'] }
          },
          order: 'createdTime DESC',
          limit,
          skip: page > 0 ? (page - 1) * limit : 0
        }
        this.isLoading = true

        const { status, orderId, store, mobile, invoice } = this.query

        if (status) {
          if (status === 'unpaid') {
            filter.where = { ...filter.where, ...{ status: { inq: ['unpaid', 'inpayment'] } } }
          } else if (status === 'completed') {
            filter.where = { ...filter.where, ...{ status: 'completed' } }
          } else if (status === 'canceled') {
            filter.where = { ...filter.where, ...{ status: { inq: ['rejected', 'cancelled', 'failed', 'expired'] } } }
          } else {
            filter.where = { ...filter.where, ...{ status: status.trim() } }
          }
        }

        if (orderId) {
          filter.where = { ...filter.where, ...{ id: orderId.trim(), status: { inq: ['rejected', 'cancelled', 'completed', 'unpaid', 'confirmed'] } } }
        }

        if (invoice) {
          filter.where = {
            ...filter.where,
            ...{ invoiceNumber: invoice.trim(), status: { inq: ['rejected', 'cancelled', 'completed', 'unpaid', 'confirmed'] } }
          }
        }

        if (mobile) {
          filter.where = { ...filter.where, ...{ status: { inq: ['rejected', 'cancelled', 'completed', 'unpaid', 'confirmed'] } } }
          filter.include = [
            {
              relation: 'items',
              scope: {
                order: 'createdTime ASC',
                include: [
                  {
                    relation: 'product'
                  }
                ]
              }
            },
            {
              relation: 'user',
              scope: {
                where: {
                  mobile: mobile.trim()
                }
              }
            },
            {
              relation: 'merchant'
            },
            {
              relation: 'payments'
            }
          ]
        }

        // if (date) {
        //   filter.where = { ...filter.where, ...{ createdTime: moment(date.trim(), 'DD/MM/YYYY') } }
        // }

        // if (name) {
        //   const data = filter.include.filter((v) => {
        //     return v.relation !== 'user'
        //   })

        //   data.push({
        //     relation: 'user',
        //     scope: {
        //       where: {
        //         name: name.trim(),
        //         status: { inq: ['rejected', 'cancelled', 'completed', 'unpaid', 'confirmed'] }
        //       }
        //     }
        //   })
        //   filter.include = data
        // }

        if (store) {
          const data = filter.include.filter(v => {
            return v.relation !== 'merchant'
          })

          data.push({
            relation: 'merchant',
            scope: {
              where: {
                name: store
              }
            }
          })
          filter.include = data
        }

        try {
          const res = await OrderServices.getAll(`filter=${JSON.stringify(filter)}`)
          if (res) this.data = res.data
        } catch (e) {
          console.log('get order error', e, e.response)
        }
        this.isLoading = false
      }
    },
    mounted() {
      this.get()
      this.status = this.$route.query.status ? this.$route.query.status : 'unpaid'
    }
  }
</script>
<style lang="scss" scoped>
  .tab-bg {
    background-color: #ccc;
    color: #000;
  }

  .activeTab {
    background-color: #a43225;
    color: #fff;
  }

  .nav-item,
  .nav-pills .nav-link {
    border-radius: 0;
  }
</style>
