<template>
  <div class="row">
    <PageHeaderComponent
      :label="`List of Vehicle`"
      :button-label="`Add Vehicle`"
      @btn-click="addVehicle"
    />
    <div
      v-if="isLoading"
      class="col-12 mb-3">
      <LoadingComponent/>
    </div>
    <SuccessComponent
      v-if="isSuccess"
    />
    <div
      v-else
      class="col-12 mb-3">

      <v-data-table
        :headers="headers"
        :items="vehicles"
        :items-per-page="15"
        class="elevation-1"
        :search="search"
      >
        <template v-slot:top>
          <div class="row">
            <div class="col-6 ml-auto">
              <input type="text" v-model="search" class="form-control" placeholder="search">
            </div>
          </div>
        </template>
        <template v-slot:item="{ item, expand, isExpanded }">
          <VehicleItemComponent
            :data="item"
            @delete="removeData($event)"
            @expand="expand(!isExpanded)"
            :isExpanded="isExpanded"
          />
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <VehicleExpandedContent :data="item" />
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import PageHeaderComponent from '@/components/misc/PageHeaderComponent'
import LoadingComponent from '@/components/misc/LoadingComponent'
import VehiclesServices from '@/service/VehiclesServices'
import VehicleItemComponent from '@/views/vehicle/components/VehicleItemComponent'
import VehicleExpandedContent from '@/views/vehicle/VehicleExpandedContent'
import SuccessComponent from '@/components/misc/SuccessComponent'

export default {
  name: 'VehicleScreen',
  components: { VehicleExpandedContent, VehicleItemComponent, LoadingComponent, PageHeaderComponent, SuccessComponent },
  data () {
    return {
      isLoading: false,
      isSuccess: false,
      vehicles: [],
      search: '',
      headers: [
        {
          text: 'Brand',
          align: 'start',
          sortable: true,
          value: 'brand'
        },
        {
          text: 'Model',
          align: 'start',
          sortable: true,
          value: 'model'
        },
        {
          text: 'Type',
          align: 'start',
          sortable: true,
          value: 'type'
        },
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Toggle', value: 'toggle', sortable: false }
      ]
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {
    addVehicle () {
      this.$router.push({
        name: 'add-vehicles'
      })
    },
    async getData () {
      this.isLoading = true
      try {
        const res = await VehiclesServices.getAll({})
        if (res && res.data) this.vehicles = [...this.vehicles, ...res.data]
      } catch (e) {
        console.log(e)
      }

      this.isLoading = false
    },
    removeData (id) {
      this.vehicles = this.vehicles.filter((v) => {
        return v.id !== id
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style lang="scss" scoped></style>
