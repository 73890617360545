<template>
  <div class="form-group">
    <label for="">
      {{ label }}
    </label>
    <input type="text" class="form-control" readonly :value="value">
  </div>
</template>
<script>
export default {
  name: 'ReadOnlyComponent',
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped></style>
