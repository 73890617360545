import Http from '../../utils/Http'

class InboxServices {
  constructor () {
    this.url = process.env.VUE_APP_API_URL + 'products'
  }

  getAll (id, params) {
    return Http.get(this.url + '/' + id + '/assets', params)
  }

  get (productId, id) {
    return Http.get(this.url + '/' + productId + '/assets/' + id, {})
  }

  update (params, id) {
    return Http.patch(this.url + '/' + id, params)
  }

  save (id, params) {
    return Http.post(this.url + '/' + id + '/assets', params)
  }

  delete (productId, id) {
    return Http.delete(this.url + '/' + productId + '/assets/' + id, {})
  }
}

export default new InboxServices()
