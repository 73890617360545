<template>
  <div class="row" data-app>
    <PageHeaderComponent
      :label="`Add Store`"
    />
    <div class="col-12">
      <div class="card">
        <div class="card-body text-left">
          <LoadingComponent v-if="isLoading"/>
          <form v-else
                @submit.prevent="submit" class="form" :disabled="!isAdmin">
            <div class="form-group row">
              <div class="col-6">
                <label for="">
                  Status
                </label>
                <select v-model="data.status" class="form-control">
                  <option value="active"> Active</option>
                  <option value="closed"> Closed</option>
                  <option value="inactive"> Inactive</option>
                  <option value="deleted"> Deleted</option>
                </select>
              </div>
              <div class="col-6">
                <label for="">
                  Region
                </label>
                <SelectRegionComponent
                  v-model="data.regionId"
                  :is-required="true"
                  :disabled="data.branchType === 'B' | data.branchType === 'F' | data.branchType === 'C'"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="">
                Store Name
              </label>
              <input :disabled="data.branchType === 'B' | data.branchType === 'F' | data.branchType === 'C'" type="text" class="form-control" v-model="data.name" required>
            </div>
            <div class="form-group">
              <label for="">
                Address
              </label>
              <textarea :disabled="data.branchType === 'B' | data.branchType === 'F' | data.branchType === 'C'" v-model="data.address" rows="5" required class="form-control"></textarea>
            </div>
            <div class="form-group">
              <label for="">
                Upload Image
              </label>
              <UploadFileComponent
                :url="data.imageUrl"
                @uploaded="data.imageUrl = $event"
              />
            </div>
            <div class="form-group row">
              <div class="col-3 d-flex align-items-center">
                Open Hour
              </div>
              <div class="col-9">
                <div class="row">
                  <div class="col-6">
                    <input :disabled="data.branchType === 'B' | data.branchType === 'F' | data.branchType === 'C'" type="number" v-model="data.openHour" required class="form-control" maxlength="2" max="24"
                           min="0">
                  </div>
                  <div class="col-6">
                    <input :disabled="data.branchType === 'B' | data.branchType === 'F' | data.branchType === 'C'" type="number" v-model="data.closeHour" required class="form-control" maxlength="2" max="24"
                           min="0">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-6">
                <label for="">
                  Open / Close Time
                </label>
                <div class="row">
                  <div class="col-6 py-0">
                    <datetime format="DD/MM/YYYY" v-model="data.openTime"></datetime>
                  </div>
                  <div class="col-6 py-0">
                    <datetime format="DD/MM/YYYY" v-model="data.closeTime"></datetime>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <label for="">Branch Type</label>
                <input :disabled="data.branchType === 'B' | data.branchType === 'F' | data.branchType === 'C'" type="text" class="form-control" v-model="data.branchType" required>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-6">
                <label for="">Phone</label>
                <input :disabled="data.branchType === 'B' | data.branchType === 'F' | data.branchType === 'C'" type="number" v-model="data.phoneNumber" class="form-control" min="0" required>
              </div>
              <div class="col-6">
                <label for="">Email Address</label>
                <input :disabled="data.branchType === 'B' | data.branchType === 'F' | data.branchType === 'C'" type="email" v-model="data.email" class="form-control">
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12 pb-0">
                <label for="">
                  Location
                </label>
                <hr class="mb-0">
              </div>
              <div class="col-6">
                <label for="">
                  Latitude
                </label>
                <input :disabled="data.branchType === 'B' | data.branchType === 'F' | data.branchType === 'C'" type="text" class="form-control" v-model="data.latitude">
              </div>
              <div class="col-6">
                <label for="">
                  Longitude
                </label>
                <input :disabled="data.branchType === 'B' | data.branchType === 'F' | data.branchType === 'C'" type="text" class="form-control" v-model="data.longitude">
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12">
                <label for="">
                  Dimensions
                </label>
                <hr class="mb-0">
              </div>
              <MultiAttributeComponent
                :btn-add-label="`Add Dimension`"
                v-model="data.dimensions"/>
            </div>
            <div class="form-group row">
              <div class="col-12">
                <label for="">
                  Attribute
                </label>
                <hr class="mb-0">
              </div>
              <MultiAttributeComponent
                :btn-add-label="`Add Attribute`"
                v-model="data"/>
            </div>
            <div class="form-group">
              <button class="btn btn-primary btn-block">SAVE</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeaderComponent from '@/components/misc/PageHeaderComponent'
import SelectRegionComponent from '@/components/select/SelectRegionComponent'
import MultiAttributeComponent from '@/views/store/MultiAttibuteComponent'
import MerchantServices from '@/service/MerchantServices'
import LoadingComponent from '@/components/misc/LoadingComponent'
import datetime from 'vuejs-datetimepicker'
import moment from 'moment'
import UploadFileComponent from '@/components/misc/UploadFileComponent'
import { mapGetters } from 'vuex'

export default {
  name: 'StoreAddScreen',
  components: { UploadFileComponent, datetime, LoadingComponent, MultiAttributeComponent, SelectRegionComponent, PageHeaderComponent },
  data () {
    return {
      isLoading: false,
      data: {
        name: '',
        status: 'active',
        createdTime: new Date(),
        updatedTime: new Date(),
        openTime: '',
        closeTime: '',
        openHour: 0,
        closeHour: 0,
        regionId: 0,
        phoneNumber: '',
        branchType: '',
        address: '',
        longitude: '',
        latitude: '',
        email: '',
        dimensions: [],
        attributes: [],
        imageUrl: ''
      },
      dateProps: {
        headerColor: 'red'
      },
      timeProps: {
        useSeconds: true,
        ampmInTitle: true
      }
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    ...mapGetters('auth', ['isAdmin', 'isMerchant', 'isOperator'])
  },
  methods: {
    async submit () {
      const params = this.data
      if (params.imageUrl === null){
          delete params.imageUrl
      }
      params.openHour = Number(params.openHour)
      params.closeHour = Number(params.closeHour)

      params.openTime = params.openTime ? moment(params.openTime, 'DD/MM/YYYY') : ''
      params.closeTime = params.closeTime ? moment(params.closeTime, 'DD/MM/YYYY') : new Date()

      const dimensions = {}
      params.dimensions.map((v) => {
        dimensions[v.key] = v.value
      })

      const attributes = {}
      params.attributes.map((v) => {
        attributes[v.key] = v.value
      })

      params.dimensions = dimensions
      params.attributes = attributes

      this.isLoading = true
      try {
        let res = null
        if (this.$route.params.id) {
          delete params.attributes
          res = await MerchantServices.update(params, this.$route.params.id)
        } else {
          res = await MerchantServices.save(params)
        }
        if (res) await this.$router.push({ name: 'store' })
      } catch (e) {
        console.log('error create merchant', e, e.response)
      }
      this.isLoading = false
    },
    async getDetail (id) {
      this.isLoading = true
      try {
        const res = await MerchantServices.get(id)
        if (res) {
          const data = res.data
          const dimensions = []
          const attributes = []
          Object.keys(data.dimensions).map((v) => {
            dimensions.push({
              key: v,
              value: data.dimensions[v]
            })
          })

          Object.keys(data.attributes).map((v) => {
            attributes.push({
              key: v,
              value: data.attributes[v]
            })
          })

          data.dimensions = dimensions
          data.attributes = attributes

          data.openTime = data.openTime ? moment(data.openTime).format('DD/MM/YYYY') : ''
          data.closeTime = data.closeTime ? moment(data.closeTime).format('DD/MM/YYYY') : ''

          this.data = data
        }
      } catch (e) {
        this.isError = true
        console.log('error get detail', e, e.response)
      }
      this.isLoading = false
    }
  },
  mounted () {
    if (this.id) this.getDetail(this.id)
  }
}
</script>
<style lang="scss" scoped></style>
