<template>
  <ModalComponent
    :is-show="isShow"
    :is-header="false"
    :is-footer="false"
    @close="$emit('close')"
  >
    <template v-slot:body>
      <div class="text-center">
        <h5 class="font-weight-bold">
          Refund settlement
        </h5>
        <div v-if="isError" class="alert alert-danger">
          Something went wrong
        </div>
        <div v-if="isSuccess" class="alert alert-success">
          Success update
        </div>
        <div v-if="isLoading">
          <LoadingComponent />
        </div>
        <form v-else @submit.prevent="update" class="refund-form">
          <div class="form-group">
            <label>Refund amount</label>
            <input type="text" v-model="amount" class="form-control" required />
          </div>

          <div class="form-group">
            <label>Notes</label>
            <textarea
              type="text"
              v-model="notes"
              class="form-control"
              required
            ></textarea>
          </div>

          <div class="form-group">
            <input
              type="checkbox"
              id="checkbox"
              v-model="isManualSettle"
              class="mr-3"
            />
            <label for="checkbox">Settlement manually</label>
          </div>

          <div class="form-group">
            <div @click="$emit('close')" class="btn btn-secondary mr-3">
              Close
            </div>

            <button class="btn btn-primary btn-success mr-3">
              Process
            </button>
          </div>
        </form>
      </div>
    </template>
  </ModalComponent>
</template>
<script>
  import ModalComponent from '@/components/misc/ModalComponent'
  import LoadingComponent from '@/components/misc/LoadingComponent'
  import RefundService from '@/service/RefundService'

  export default {
    name: 'ModalSettledComponent',
    components: { LoadingComponent, ModalComponent },
    props: {
      isShow: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        isLoading: false,
        amount: this.data.amount,
        notes: this.data.notes,
        isManualSettle: false,
        isSuccess: false,
        isError: false
      }
    },
    methods: {
      async update() {
        this.isLoading = true
        this.isError = false
        this.isSuccess = false
        try {
          const params = {
            amount: parseInt(this.amount),
            notes: this.notes,
            manual: this.isManualSettle,
            status: 'settled'
          }
          const res = await RefundService.update(params, this.data.id)
          if (res) {
            this.isSuccess = true
            this.notes = ''
            this.$emit('settled', params)
            this.$emit('showNotif', { message: 'Refund Approve' })
            this.$emit('close')
          }
        } catch (e) {
          console.log('failed update', e)
          this.isError = true
        }
        this.isLoading = false
      }
    }
  }
</script>
<style lang="scss" scoped>
  .refund-form {
    margin-top: 30px;
  }
</style>
