<template>
  <div class="col-12">
    <div
      v-for="(val, key) in mutateData"
      :key="key"
      class="row">
      <div class="col-6">
        <input type="text"
               placeholder="key"
               @input="handleData('key', $event.target.value, key)"
               class="form-control" :value="val.key">
      </div>
      <div class="col-6">
        <input
          placeholder="value"
          @input="handleData('value', $event.target.value, key)"
          type="text" class="form-control" :value="val.value">
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div
          @click="addData"
          class="btn btn-sm">
          <i class="fas fa-plus"></i> {{ btnAddLabel }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const defaultObject = () => {
  return {
    key: '',
    value: ''
  }
}
export default {
  name: 'MultiAttributeComponent',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    btnAddLabel: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      mutateData: []
    }
  },
  methods: {
    handleData (type, value, key) {
      const data = this.mutateData[key]
      data[type] = value

      this.mutateData[key] = data
      this.$emit('input', this.mutateData)
    },
    addData () {
      this.mutateData.push(defaultObject())
    }
  },
  mounted () {
    this.mutateData = this.value
  }
}
</script>
<style lang="scss" scoped></style>
