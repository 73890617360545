// auth.js
import axios from 'axios'
import Vue from 'vue'
import { setHeaderToken, removeHeaderToken } from '../../utils/auth'

export default {
    namespaced: true,
    state: {
        user: null,
        isLoggedIn: false
    },
    mutations: {
        set_user(state, data) {
            state.user = data
            state.isLoggedIn = true
        },
        reset_user(state) {
            state.user = null
            state.isLoggedIn = false
            Vue.$cookies.remove('token')
        }
    },
    getters: {
        isLoggedIn(state) {
            return state.isLoggedIn
        },
        user(state) {
            return state.user
        },
        isMerchant(state) {
            const { user } = state
            return user && user.realm === 'admin' && user.roles.indexOf('merchant') !== -1
        },
        isAdmin(state) {
            const { user } = state
            return user && user.realm === 'admin' && user.roles.indexOf('admin') !== -1
        },
        isOperator(state) {
            const { user } = state
            return user && user.realm === 'admin' && user.roles.indexOf('operator') !== -1
        },
        isMember(state) {
            const { user } = state
            return user && user.realm === 'member'
        }
    },
    actions: {
        async login({ dispatch, commit }, data) {
            return new Promise((resolve, reject) => {
                axios.post('users/login', data)
                    .then(response => {
                        const token = response.data.token
                        Vue.$cookies.set('token', token)
                        dispatch('get_user')
                        resolve(response)
                    })
                    .catch(err => {
                        commit('reset_user')
                        Vue.$cookies.remove('token')
                        reject(err)
                    })
            })
        },
        async get_user({ commit }) {
            try {
                setHeaderToken()
                const response = await axios.get('users/me')
                commit('set_user', response.data)
            } catch (error) {
                commit('reset_user')
                removeHeaderToken()
                Vue.$cookies.remove('token')
            }
        }
    }
}