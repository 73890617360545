<template>
  <div class="row">
    <PageHeaderComponent
      :label="`Product Assets`"
    />
    <div class="col-12 mb-3">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active">
            <router-link to="/">
              Home
            </router-link>
          </li>
          <li class="breadcrumb-item active">
            <router-link :to="{name: 'products'}">
              List of Product
            </router-link>
          </li>
          <li class="breadcrumb-item active">Product Assets</li>
        </ol>
      </nav>
    </div>
    <ProductAssetUploadComponent @uploaded="getFiles"/>
    <div class="col-12">
      <LoadingComponent v-if="isLoading" />
      <v-data-table
        v-else
        :headers="headers"
        :items="data"
        :items-per-page="15"
        class="elevation-1"
      >
        <template v-slot:item="{ item }">
          <ProductAssetItemComponent :data="item" @delete="getFiles" />
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import PageHeaderComponent from '@/components/misc/PageHeaderComponent'
import ProductAssetUploadComponent from '@/views/product/ProductAssetUploadComponent'
import LoadingComponent from '@/components/misc/LoadingComponent'
import ProductAssetItemComponent from '@/views/product/ProductAssetItemComponent'
import ProductAssetService from '@/service/ProductAssetService'
export default {
  name: 'ProductAssetScreen',
  components: { ProductAssetItemComponent, LoadingComponent, ProductAssetUploadComponent, PageHeaderComponent },
  computed: {
    productId () {
      return this.$route.params.id
    }
  },
  data () {
    return {
      isLoading: false,
      data: [],
      headers: [
        {
          text: 'Image',
          align: 'start',
          sortable: true,
          value: 'url'
        },
        { text: 'Created Date', value: 'createdTime' },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    }
  },
  methods: {
    async getFiles () {
      this.isLoading = true
      try {
        const res = await ProductAssetService.getAll(this.productId, {})
        if (res) this.data = res.data
      } catch (e) {
        console.log('error', e, e.response)
      }
      this.isLoading = false
    }
  },
  mounted () {
    this.getFiles()
  }
}
</script>
<style lang="scss" scoped></style>
