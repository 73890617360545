import moment from 'moment'
class Helper {
    generateErrorMessage(err) {
        let text = err
        const { data } = err
        if (data) {
            const { error } = err.data
            if (error && error.details && error.details.length) {
                text = '<ul style="list-style: none; margin: 0; padding: 0">'
                error.details.map(v => {
                    if (v.message) {
                        text += `<li>${v.message}</li>`
                    }
                })
                text += '</ul>'
            } else if(error && error.code && error.message) {
              if(error.code === "ER_DUP_ENTRY") {
                text = 'Data duplikat tidak diizinkan. Silahkan coba lagi.<br/>Reason: '+error.message
              } else {
                text = error.message
              }
            } else if(error && error.message) {
              text = error.message
            }

            if (!text) text = 'Something Went Wrong'
        }
        return text
    }

    dateFormatter(str, isTime = false) {
        if (str && isTime) return moment(str).format('DD-MMM-YYYY HH:mm:ss')
        if (str) return moment(str).format('DD-MMM-YYYY')
        return ''
    }

    dateFormatterMember(str) {
        return moment(str).format('DD-MMM-YYYY HH:mm:ss')
    }

    dateFormatterMemberDetail(str) {
        return moment(str).format('DD-MMM-YYYY')
    }

    numberFormatter(number) {
        if (number) {
            const num = String(number)
            return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        }
        return 0
    }

    kFormatter(num) {
        return Math.abs(num) > 999 ?
            `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}k` :
            Math.sign(num) * Math.abs(num)
    }
}

export default new Helper()
