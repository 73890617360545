<template>
  <div class="col-12">
    <div class="card">
      <div class="card-body text-left">
        <form @submit.prevent="submit">
          <div class="form-group row">
            <div class="col-6">
              <label for="">
                Order Id
              </label>
              <input type="text" v-model="orderId" placeholder="order id" class="form-control">
            </div>
            <div class="col-6">
              <label for="">
                Invoice ID
              </label>
              <input type="text" v-model="invoice" placeholder="Invoice ID" class="form-control">
            </div>
          </div>
          <div class="form-group row">
            <div class="col-6">
              <label for="">
                Mobile Number
              </label>
              <input type="text" v-model="mobile" placeholder="Ex: 6281xxxxxx" class="form-control">
            </div>
          </div>
          <div class="form-group">
            <button class="btn btn-success mr-3">
              Search
            </button>
            <button
              @click="handleReset"
              class="btn btn-secondary">
              Reset
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'TransactionSearchComponent',
  components: { },
  data () {
    return {
      orderId: '',
      mobile: '',
      invoice: '',
      date: ''
    }
  },
  computed: {
    query () {
      return this.$route.query
    }
  },
  methods: {
    handleReset () {
      this.$router.push({
        name: 'transaction'
      }).catch()
    },
    submit () {
      this.$router.push({
        name: 'transaction',
        query: {
          ...this.query,
          ...{
            orderId: this.orderId,
            mobile: this.mobile,
            invoice: this.invoice,
            date: this.date
          }
        }
      }).catch()
    }
  },
  watch: {
    query (val) {
      if (val) {
        this.mobile = val.mobile
        this.invoice = val.invoice
        this.date = val.date
        this.orderId = val.orderId
      }
    }
  },
  mounted () {
    const { mobile, invoice, date, orderId } = this.query

    this.mobile = mobile
    this.invoice = invoice
    this.date = date
    this.orderId = orderId
  }
}
</script>
<style lang="scss" scoped>
.col- * {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
