import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from 'axios'
import { setHeaderToken } from '../utils/auth'
import VueCookies from 'vue-cookies'
import Helper from '../utils/Helper'

import '@coreui/coreui/dist/css/coreui.min.css'
import '@coreui/icons/css/all.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import './assets/styles/styles.scss'
import vuetify from './plugins/vuetify'
import wysiwyg from 'vue-wysiwyg'
import VTooltip from 'v-tooltip'
import VueHtmlToPaper from 'vue-html-to-paper'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
Vue.use(wysiwyg, {})
Vue.use(VTooltip)
Vue.use(VueHtmlToPaper)
Vue.use(VueCookies)
Vue.$cookies.config('7d')
Vue.use(VueLodash, { name: 'custom', lodash: lodash })
Vue.prototype.$helper = Helper

axios.defaults.baseURL = process.env.VUE_APP_API_URL
Vue.config.productionTip = false

const token = Vue.$cookies.get('token')

if (token) {
    setHeaderToken(token)
}

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')