<template>
      <div
        class="container-expand text-left">
        <div class="row">
          <div class="col-6">
            <p>
              Brand : {{ data.brand }}
            </p>
            <p>
              Model : {{ data.model }}
            </p>
            <p>
              Type: {{ data.type }}
            </p>
            <p>
              Engine Oil Spec : {{ data.engineOilSpec }}
            </p>
            <p>
              Gear Oil Spec: {{ data.gearOilSpec }}
            </p>
            <p>
              Battery Spec : {{ data.batterySpec }}
            </p>
            <p>
              Spark Spec: {{ data.sparkSpec }}
            </p>
          </div>
          <div class="col-6">
            <p>
              Tire Front Spec: {{ data.tireFrontSpec }}
            </p>
            <p>
              Tire Front Upsize Spec: {{ data.tireFrontUpsizeSpec }}
            </p>
            <p>
              Brake Front Spec: {{ data.brakeFrontSpec }}
            </p>
            <p>
              Tire Rear Spec: {{ data.tireRearSpec }}
            </p>
            <p>
              Tire Rear Upsize Spec: {{ data.tireRearUpsizeSpec }}
            </p>
            <p>
              Brake Rear Spec: {{ data.brakeRearSpec }}
            </p>
          </div>
        </div>
      </div>
</template>
<script>
export default {
  name: 'VehicleExpandedContent',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
.container-expand {
  background-color: #eee;
  padding: 1.5rem
}
</style>
