<template>
  <ModalComponent
    :is-show="isShow"
    :is-header="false"
    :is-footer="false"
    @close="$emit('close')"
  >
    <template v-slot:body>
      <div class="text-center">
        <h5 class="font-weight-bold">
          Change Password
        </h5>
        <div
          v-if="isError"
          class="alert alert-danger">
          Something went wrong
        </div>
        <div
          v-if="isSuccess"
          class="alert alert-success">
          Success update password
        </div>
        <div v-if="isLoading">
          <LoadingComponent />
        </div>
        <form
          v-else
          @submit.prevent="updatePassword">
          <div class="form-group">
            <input type="text" v-model="password" class="form-control" required>
          </div>
          <div class="form-group">
            <button class="btn btn-primary mr-3">
              Save
            </button>
            <div
              @click="$emit('close')"
              class="btn btn-secondary">
              Close
            </div>
          </div>
        </form>
      </div>
    </template>
  </ModalComponent>
</template>
<script>
import ModalComponent from '@/components/misc/ModalComponent'
import UserServices from '@/service/UserServices'
import LoadingComponent from '@/components/misc/LoadingComponent'
export default {
  name: 'AdminUpdatePasswordModalComponent',
  components: { LoadingComponent, ModalComponent },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      password: '',
      isLoading: false,
      isError: false,
      isSuccess: false
    }
  },
  methods: {
    async updatePassword () {
      this.isLoading = true
      this.isError = false
      this.isSuccess = false
      try {
        const params = {
          password: this.password.trim()
        }
        const res = await UserServices.resetPassword(this.data.id, params)
        if (res) {
          this.isSuccess = false
          this.password = ''
          this.$emit('close')
          this.$emit('showNotif')
        }
      } catch (e) {
        console.log('failed reset password', e)
        this.isError = true
      }
      this.isLoading = false
    }
  }
}
</script>
<style lang="scss" scoped></style>
