<template>
  <div
    v-if="product.category !== 'promosi-barang-promo'"
    class="col-12 mb-3 itemContainer"
  >
    <div class="row">
      <div class="col-2">
        <div
          :style="{ backgroundImage: `url(${assetUrl})` }"
          class="standard-thumbnail product-thumbnail imgItem"
        ></div>
      </div>
      <div class="col-6 align-items-center d-flex">
        {{ product.name }}<br />
        SKU: {{ product.sku }}
      </div>
      <div class="col-2 align-items-center d-flex">
        Qty: {{ data.quantity }}
      </div>
      <div class="col-2 justify-content-center align-items-center d-flex">
        IDR {{ $helper.numberFormatter(data.price * data.quantity) }}
      </div>
    </div>
  </div>
</template>
<script>
  const image = require('@/assets/images/image-placeholder.jpeg')
  export default {
    name: 'OrderProductComponent',
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        image
      }
    },
    computed: {
      product() {
        return this.data.product
      },
      assetUrl() {
        if (this.product && this.product.thumbnailUrl) {
          return this.product.thumbnailUrl ? this.product.thumbnailUrl : image
        }
        return image
      }
    }
  }
</script>
<style lang="scss" scoped>
  .itemContainer {
    border-bottom: solid 1px #eee;
  }
  .imgItem {
    background-size: contain;
  }
</style>
