<template>
  <div class="row">
    <div
      v-if="isLoading"
      class="col-12">
      <LoadingComponent/>
    </div>
    <div
      v-else
      class="col-12 mb-3 text-left">
      <h4>My Profile</h4>
      <div class="card">
        <div class="card-body">
          <ReadOnlyComponent
            :label="`Id`"
            :value="user.id"
          />
          <ReadOnlyComponent
            :label="`Name`"
            :value="user.name"
          />
          <ReadOnlyComponent
            :label="`Email`"
            :value="user.email"
          />
          <ReadOnlyComponent
            :label="`Mobile`"
            :value="user.mobile"
          />
          <ReadOnlyComponent
            :label="`Gender`"
            :value="user.gender"
          />
          <ReadOnlyComponent
            :label="`BirthDate`"
            :value="user.birthdate ? $helper.dateFormatter(user.birthdate) : '-'"
          />
          <ReadOnlyComponent
            :label="`Created at`"
            :value="user.createdTime ? $helper.dateFormatter(user.createdTime) : '-'"
          />
          <ReadOnlyComponent
            :label="`Activate at`"
            :value="user.activeTime ? $helper.dateFormatter(user.activeTime) : '-'"
          />
          <ReadOnlyComponent
            :label="`membership Id`"
            :value="user.membershipId"
          />
          <ReadOnlyComponent
            :label="`citizen Id`"
            :value="user.citizenId"
          />
          <ReadOnlyComponent
            :label="`license Id`"
            :value="user.licenseId"
          />
          <ReadOnlyComponent
            :label="`employee Id`"
            :value="user.employeeId"
          />
          <ReadOnlyComponent
            :label="`merchant Id`"
            :value="user.merchantId"
          />
          <ReadOnlyComponent
            :label="`Roles`"
            :value="user.roles[0]"
          />
          <ReadOnlyComponent
            :label="`Branch`"
            :value="user.branchCode"
          />
        </div>
      </div>
      <ChangePassword/>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import LoadingComponent from '@/components/misc/LoadingComponent'
import ChangePassword from '@/views/profile/ChangePassword'
import ReadOnlyComponent from '@/views/my_profile/components/ReadOnlyComponentLabel'

export default {
  name: 'MyProfileScreen',
  components: { ReadOnlyComponent, ChangePassword, LoadingComponent },
  data () {
    return {
      isError: false,
      isLoading: false
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  }
}
</script>
<style lang="scss" scoped></style>
