import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import RegionScreen from '@/views/region/RegionScreen'
import StoreScreen from '@/views/store/StoreScreen'
import Logout from '@/views/Logout'
import VehicleScreen from '@/views/vehicle/VehicleScreen'
import VehicleAddScreen from '@/views/vehicle/VehicleAddScreen'
import ManufactureScreen from '@/views/manufacture/ManufactureScreen'
import ProductScreen from '@/views/product/ProductScreen'
import PromoScreen from '@/views/promo/PromoScreen'
import AddPromoScreen from '@/views/promo/AddPromoScreen'
import StoreAddScreen from '@/views/store/StoreAddScreen'
import InboxScreen from '@/views/inbox/InboxScreen'
import CreateNotificationScreen from '@/views/inbox/CreateNotificationScreen'
import ListAdminScreen from '@/views/admin/ListAdminScreen'
import ListMemberScreen from '@/views/member/MemberScreen'
import ProductAssetScreen from '@/views/product/ProductAssetScreen'
import MemberDetailScreen from '@/views/member/MemberDetailScreen'
import EditMember from '@/views/member/EditMember'
import TransactionScreen from '@/views/transaction/TransactionScreen'
import MemberVoucher from '@/views/member/MemberVoucher'
import VoucherScreen from '@/views/voucher/VoucherScreen'
import AddVoucher from '@/views/voucher/AddVoucher'
import RefundScreen from '@/views/refund/RefundScreen'
import DownloadInvoice from '@/views/dashboard/DownloadInvoice'
import MyProfileScreen from '@/views/my_profile/MyProfileScreen'
import AccountDeletionScreen from '@/views/user/AccountDeletionScreen.vue'

const DashboardLayout = () =>
  import('../views/layout/DashboardLayout')
const SettingScreen = () =>
  import('../views/settings/SettingsScreen')

Vue.use(VueRouter)

const routes = [{
  path: '/',
  redirect: '/dashboard',
  component: DashboardLayout,
  children: [{
    path: 'dashboard',
    name: 'dashboard',
    component: () =>
      import('../views/dashboard/Dashboard.vue'),
    meta: {
      isAuth: true
    }
  },
  {
    path: 'refund',
    name: 'refund',
    component: RefundScreen,
    meta: {
      isAuth: true
    }
  },
  {
    path: 'transaction',
    name: 'transaction',
    component: TransactionScreen,
    meta: {
      isAuth: true
    }
  },
  {
    path: 'settings',
    name: 'settings',
    component: SettingScreen,
    meta: {
      isAuth: true
    }
  },
  {
    path: 'my-profile',
    name: 'my-profile',
    component: MyProfileScreen,
    meta: {
      isAuth: true
    }
  },
  {
    path: 'products',
    component: {
      render (c) {
        return c('router-view')
      }
    },
    children: [{
      path: '/',
      name: 'products',
      component: ProductScreen,
      meta: {
        isAuth: true
      }
    },
    {
      path: 'manufacture',
      name: 'manufacture',
      component: ManufactureScreen,
      meta: {
        isAuth: true
      }
    },
    {
      path: 'vehicles',
      component: {
        render (c) {
          return c('router-view')
        }
      },
      children: [{
        path: '/',
        name: 'vehicles',
        component: VehicleScreen,
        meta: {
          isAuth: true
        }
      },
      {
        path: 'add',
        name: 'add-vehicles',
        component: VehicleAddScreen,
        meta: {
          isAuth: true
        }
      },
      {
        path: ':id',
        name: 'detail-vehicles',
        component: VehicleAddScreen,
        meta: {
          isAuth: true
        }
      }
      ]
    },
    {
      path: ':id/assets',
      name: 'product-assets',
      component: ProductAssetScreen,
      meta: {
        isAuth: true
      }
    }
    ]
  },
  {
    path: 'store',
    component: {
      render (c) {
        return c('router-view')
      }
    },
    children: [{
      path: '/',
      name: 'store',
      component: StoreScreen,
      meta: {
        isAuth: true
      }
    },
    {
      path: 'add',
      name: 'add-store',
      component: StoreAddScreen,
      meta: {
        isAuth: true
      }
    },
    {
      path: 'region',
      component: {
        render (c) {
          return c('router-view')
        }
      },
      children: [{
        path: '/',
        name: 'Region',
        component: RegionScreen,
        meta: {
          isAuth: true
        }
      }]
    },
    {
      path: ':id',
      name: 'store-detail',
      component: StoreAddScreen,
      meta: {
        isAuth: true
      }
    }
    ]
  },
  {
    path: 'promos',
    component: {
      render (c) {
        return c('router-view')
      }
    },
    children: [{
      path: '/',
      name: 'promos',
      component: PromoScreen,
      meta: {
        isAuth: true
      }
    },
    {
      path: 'add',
      name: 'add-promo',
      component: AddPromoScreen,
      meta: {
        isAuth: true
      }
    },
    {
      path: ':id',
      name: 'edit-promo',
      component: AddPromoScreen,
      meta: {
        isAuth: true
      }
    }
    ]
  },
  {
    path: 'inbox',
    component: {
      render (c) {
        return c('router-view')
      }
    },
    children: [{
      path: '/',
      name: 'inbox',
      component: InboxScreen,
      meta: {
        isAuth: true
      }
    },
    {
      path: 'create',
      name: 'create-notification',
      component: CreateNotificationScreen,
      meta: {
        isAuth: true
      }
    }
    ]
  },
  {
    path: 'voucher',
    component: {
      render (c) {
        return c('router-view')
      }
    },
    children: [{
      path: '/',
      name: 'voucherList',
      component: VoucherScreen,
      meta: {
        isAuth: true
      }
    },
    {
      path: 'add-voucher',
      name: 'add-voucher',
      component: AddVoucher,
      meta: {
        isAuth: true
      }
    },
    {
      path: ':id',
      name: 'voucher-detail',
      component: AddVoucher,
      meta: {
        isAuth: true
      }
    }
    ]
  },
  {
    path: 'members',
    component: {
      render (c) {
        return c('router-view')
      }
    },
    children: [{
      path: 'admin',
      name: 'admin',
      component: ListAdminScreen,
      meta: {
        isAuth: true
      }
    },
    {
      path: '/',
      name: 'members',
      component: ListMemberScreen,
      meta: {
        isAuth: true
      }
    },
    {
      path: ':id',
      name: 'member-detail',
      component: MemberDetailScreen,
      meta: {
        isAuth: true
      }
    },
    {
      path: '/edit/:id',
      name: 'edit-member',
      component: EditMember,
      meta: {
        isAuth: true
      }
    },
    {
      path: '/voucher/:id',
      name: 'member-voucher',
      component: MemberVoucher,
      meta: {
        isAuth: true
      }
    }

    ]
  }
  ]
},
{
  path: '/logout',
  name: 'logout',
  component: Logout,
  meta: {
    isAuth: true
  }
},
{
  path: '/login',
  name: 'login',
  component: () =>
    import('../views/Login.vue'),
  meta: {
    isAuth: false
  }
},
{
  path: '/downloadinvoice/:id',
  name: 'downloadinvoice',
  component: DownloadInvoice,
  meta: {
    isAuth: true
  }
},
{
  path: '/account-deletion',
  name: 'delete-user',
  component: AccountDeletionScreen,
  meta: {
    isAuth: false
  }
},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const token = Vue.$cookies.get('token')
  if (token) {
    if (!store.state.auth.user && token) {
      await store.dispatch('auth/get_user')
    }
  }
  if (!to.meta.isAuth) {
    if (!token) {
      return next()
    }
  } else {
    if (!token) {
      return next({
        name: 'login'
      })
    } else {
      return next()
    }
  }
})

export default router
