<template>
  <div class="col-12">
    <div class="card mb-0">
      <div class="card-header border-bottom-0">
        <div class="row">
          <div class="col-6 text-left d-flex align-items-center">
            <strong> <h5 class="m-0">{{ label }}</h5></strong>
          </div>
          <div class="col-6 text-right">
            <button
              @click="$emit('btn-click')"
              v-if="buttonLabel"
              class=" btn btn-sm">
                <i v-if="$route.name==='products' || $route.name ==='manufacture'" class="fas fa-sync"></i>
                <i v-else class="fas fa-plus"></i> {{ buttonLabel}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PageHeaderComponent',
  props: {
    label: {
      type: String,
      required: true
    },
    buttonLabel: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  background-color: transparent;
  border: none;
  .card-header {
    background-color: transparent;
  }
}
</style>
