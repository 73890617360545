<template>
  <div class="row">
    <PageHeaderComponent
      :label="`List of Manufacture`"
      :button-label="`Sync Manufacture`"
      @btn-click="syncManufacture"
    />
    <div class="row">
      <div class="col-12 searchContainer">
        <div class="card">
          <div class="card-body text-left">
              <h5 class="font-weight-bold"> Search </h5>
              <input type="text" v-model="search" class="form-control" placeholder="search">
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <v-data-table
        :headers="headers"
        :items="manufactures"
        :items-per-page="15"
        class="elevation-1"
        :search="search"
      >
        <template v-slot:item="{ item }">
          <ManufactureItemComponent
            :value="item"
            @save="updateData($event)"
            @delete="removeData($event)"
          />
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import PageHeaderComponent from '@/components/misc/PageHeaderComponent'
import ManufactureItemComponent from '@/views/manufacture/component/ManufactureItemComponent'
import ManufactureServices from '@/service/ManufactureServices'
export default {
  name: 'ManufactureScreen',
  components: { ManufactureItemComponent, PageHeaderComponent },
  data () {
    return {
      isLoading: true,
      search: '',
      manufactures: [],
      headers: [
        {
          text: 'Manufacture ID',
          align: 'start',
          sortable: true,
          value: 'id'
        },
        {
          text: 'Manufacture Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Icon',
          align: 'start',
          sortable: false,
          value: 'iconUrl'
        },
        { text: 'Created Date', value: 'createdTime' },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    }
  },
  methods: {
    async getAll () {
      this.isLoading = true
      try {
        const res = await ManufactureServices.getAll({})
        if (res && res.data) this.manufactures = [...this.manufactures, ...res.data]
      } catch (e) {
        console.log(e)
      }
      this.isLoading = false
    },
    async syncManufacture () {
      this.isLoading = true
      try {
        await ManufactureServices.sync()
        window.location.reload()
      } catch (e) {
        console.log('error', e)
      }
      this.isLoading = false
    },
    updateData (data, key) {
      data.isEditable = false
      this.manufactures[key] = data
    },
    removeData (id) {
      this.manufactures = this.manufactures.filter((v) => {
        return v.id !== id
      })
    }
  },
  mounted () {
    this.getAll()
  }
}
</script>
<style lang="scss" scoped>
  .searchContainer{
    padding-left:20px;
  }
</style>
