<template>
  <div class="col-12 mr-auto">
    <div class="card text-left">
      <div class="card-header">
        Search Member
      </div>
      <div class="card-body">
        <form @submit.prevent="submit">
          <div class="form-group row">
            <div class="col-12">
              <label>
                Search
              </label>
              <input placeholder="By Member ID / Phone / Email / Citizen Id / Name" type="text" class="form-control" v-model="search">
            </div>
          </div>
          <div class="form-group">
            <button class="btn btn-success btn-block">
              Search
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MemberSearchComponent',
  data () {
    return {
      search: ''
    }
  },
  watch: {
    '$route.query' (val) {
      this.search = val.search
    }
  },
  methods: {
    submit () {
      this.$router.push({
        name: 'members',
        query: {
          search: this.search
        }
      })
    }
  },
  mounted () {
    
  }
}
</script>
<style lang="scss" scoped>
.col-6 {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
