<template>
  <tr>
    <td>
      <a
        :href="data.url"
        target="_blank"
        class="text-white">
        <img class="thumb" :src="data.url"/>
      </a>
    </td>
    <td>
      {{ $helper.dateFormatter(data.createdTime)}}
    </td>
    <td>
      <div
        @click="remove"
        class="btn btn-danger btn-sm">
        <i class="fas fa-trash"></i>
      </div>
    </td>
  </tr>
</template>
<script>
import ProductAssetService from '@/service/ProductAssetService'

export default {
  name: 'ProductAssetItemComponent',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    async remove () {
      await ProductAssetService.delete(this.data.productId, this.data.id)
      this.$emit('delete')
    }
  }
}
</script>
<style lang="scss" scoped>
.thumb {
  width:150px;
}
</style>
