<template>
  <tr v-if="data && user">
    <td>
      {{data.invoiceNumber}}
    </td>
    <td>
      {{data.serviceCode}}
    </td>
    <td>
      {{ merchant ? merchant.name  : '-'}}
    </td>
    <td>
      {{$helper.dateFormatterMember(data.createdTime)}}
    </td>
    <td> {{ $helper.dateFormatterMember(data.deliveryDate) }}</td>
    <td> {{user.name}} </td>
    <td>
      IDR {{ $helper.numberFormatter(totalAmount)}}
    </td>
    <td>{{ data.status }}</td>
    <td>
      <div
        @click="$emit('expand')"
        class="btn btn-sm">
        <i
          :class="[isExpanded ? 'fa-chevron-down' : 'fa-chevron-right']"
          class="fas"></i>
      </div>
    </td>
  </tr>
</template>
<script>
export default {
  name: 'TransactionItemComponent',
  props: {
    data: {
      type: Object,
      required: true
    },
    isExpanded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    merchant () {
      return this.data.merchant
    },
    user () {
      return this.data.user
    },
    items () {
      return this.data.items && this.data.items.length ? this.data.items : []
    },
    totalAmount () {
      let price = 0
      if (this.items.length) {
        this.items.map((v) => {
          price += (v.price * v.quantity)
        })
      }
      return price
    }
  }
}
</script>
<style lang="scss" scoped></style>
