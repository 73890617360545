<template>
  <div class="card">
    <div class="card-body text-left">
      <div v-if="isError">
        <div class="alert alert-danger">
          {{ message }}
        </div>
      </div>
      <div v-if="isSuccess">
        <div class="alert alert-success">
          Password updated
        </div>
      </div>
      <h5>
        Change Password
      </h5>
      <form @submit.prevent="submit">
        <div class="form-group">
          <label for="">
            Current Password
          </label>
          <input type="password" class="form-control"
                 v-model="currentPassword"
                 minlength="6"
                 placeholder="Current Password" required>
        </div>
        <div class="form-group">
          <label for="">
            New Password
          </label>
          <input type="password"
                 v-model="newPassword"
                 minlength="6"
                 class="form-control" placeholder="New Password" required>
        </div>
        <div class="form-group">
          <label for="">
            Confirm Password
          </label>
          <input type="password"
                 v-model="confirmPassword"
                 minlength="6"
                 class="form-control" placeholder="Confirm Password" required>
        </div>
        <div class="form-group">
          <button class="btn btn-success btn-block">
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import UserServices from '@/service/UserServices'

export default {
  name: 'ChangePassword',
  data () {
    return {
      isError: false,
      isLoading: false,
      message: 'New password and Confirm password not match',
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      isSuccess: false
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('auth', ['get_user']),
    async submit () {
      this.isError = false
      this.isSuccess = false
      if (this.confirmPassword !== this.newPassword) {
        this.isError = true
      } else {
        this.isLoading = true
        try {
          const res = await UserServices.changePassword(this.user.id, {
            currentPassword: this.currentPassword,
            password: this.newPassword
          })
          if (res && res.data) {
            const token = res.data.token
            this.$cookies.set('token', token)
            await this.get_user()
            this.isSuccess = true
          }
        } catch (e) {
          this.isError = true
          this.message = this.$helper.generateErrorMessage(e.response)
        }

        this.isLoading = false
      }
    }
  }
}
</script>
