import Http from '../../utils/Http'

class RefundService {
  constructor () {
    this.url = process.env.VUE_APP_API_URL + '/refunds'
  }

  getAll (params) {
    return Http.get(this.url, params)
  }

  get (id) {
    return Http.get(this.url + '/' + id, {})
  }

  update (params, id) {
    return Http.patch(this.url + '/' + id, params)
  }

  save (params) {
    return Http.post(this.url, params)
  }
}

export default new RefundService()
