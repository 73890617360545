<template>
  <div class="row" data-app>
    <PageHeaderComponent
      :label="`Vouchers`"
    />
    <div class="col-12">
      <LoadingComponent v-if="isLoading"/>
      <v-data-table
        v-else
        :headers="headers"
        :items="data"
        :items-per-page="15"
        class="elevation-1"
        :search="search"
      >
        <template v-slot:item="{ item }">
          <VoucherItemComponent :data="item"/>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import PageHeaderComponent from '@/components/misc/PageHeaderComponent'
import LoadingComponent from '@/components/misc/LoadingComponent'
import VoucherItemComponent from '@/views/member/component/VoucherItemComponent'
import UserServices from '@/service/UserServices'

export default {
  name: 'voucherList',
  components: { PageHeaderComponent, LoadingComponent, VoucherItemComponent },
  data () {
    return {
      isLoading: false,
      search: '',
      data: [],
      headers: [
        {
          text: 'User Id',
          align: 'start',
          sortable: true,
          value: 'userId'
        },
        {
          text: 'Voucher',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Code',
          align: 'start',
          sortable: true,
          value: 'code'
        },
        {
          text: 'Created Date',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Expired Date',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Status',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: 'Notes', value: 'phone' }
      ]
    }
  },
  methods: {
    addVoucher () {
      this.$router.push({
        name: 'add-voucher'
      })
    },
    async getAll () {
      this.isLoading = true
      try {
        const params = {
          filter: {
            include: [
              {
                relation: 'user'
              }],
            limit: 15
          }
        }
        params.filter = JSON.stringify(params.filter)

        const res = await UserServices.userVouchers(this.$route.params.id, params.filter)
        if (res && res.data) this.data = res.data
      } catch (e) {
        console.log('get data voucher', e, e.response)
      }
      this.isLoading = false
    },
    handleDelete (e) {
      this.promos = this.promos.filter((v) => {
        return v.id !== e
      })
    }
  },
  mounted () {
    this.getAll()
  }
}
</script>
