<template>
  <tr>
    <td>{{ data.id }}
      <input
        v-if="isEditable && data.isNew"
        type="text" class="form-control" v-model="data.id">
      <span v-else> {{ data.id }}</span>
      <small
        v-if="isError"
        class="text-danger">This field is required</small>
    </td>
    <td>
      <input
        v-if="isEditable"
        type="text" class="form-control" v-model="data.name">
      <span v-else> {{ data.name }}</span>
      <small
        v-if="isError"
        class="text-danger">This field is required</small>
    </td>
    <td>
      <UploadFileComponent
        v-if="isEditable"
        :is-edit="isEditable"
        :url="data.iconUrl"
        @uploaded="handleUploadData"
      />
      <a
        v-if="data.iconUrl && !isEditable"
        :href="data.iconUrl"
        target="_blank"
        class="btn btn-secondary text-white">
        <img style="width:50px;" :src="data.iconUrl">
      </a>
    </td>
    <td>
      {{ this.$helper.dateFormatter(data.createdTime) }}
    </td>
    <td
      v-if="isLoading">
      <LoadingComponent/>
    </td>
    <td v-else>
      <div
        v-tooltip="'Save Edit'"
        v-if="!data.id || isEditable"
        @click="save"
        class="btn btn-primary btn-sm mr-3">
        <i class="fas fa-save"></i>
      </div>
      <div v-tooltip="'Edit Data'"
        v-else
        @click="editData"
        class="btn btn-secondary mr-3 btn-sm">
        <i class="fas fa-edit"></i>
      </div>
      <div
        v-tooltip="'Delete'"
        v-if="isAdmin"
        @click="remove"
        class="btn btn-danger btn-sm">
        <i class="fas fa-trash"></i>
      </div>
      <div v-tooltip="'Cancel Edit'"
        v-if="isEditable"
        @click="cancelEdit"
        title="Assets"
        class="btn btn-danger btn-sm">
        <i class="fas fa-times"></i>
      </div>
    </td>
  </tr>
</template>
<script>
import { mapGetters } from 'vuex'
import LoadingComponent from '@/components/misc/LoadingComponent'
import ManufactureServices from '@/service/ManufactureServices'
import UploadFileComponent from '@/components/misc/UploadFileComponent'

const defaultData = () => {
  return {
    id: '',
    name: '',
    createdTime: '',
    updatedTime: '',
    iconUrl: ''
  }
}
export default {
  name: 'ManufactureItemComponent',
  components: { UploadFileComponent, LoadingComponent },
  props: {
    value: {
      type: Object,
      default: defaultData()
    }
  },
  data () {
    return {
      isLoading: false,
      isError: false,
      data: defaultData()
    }
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isMerchant', 'isOperator']),
    isEditable () {
      return this.data.isEditable
    }
  },
  methods: {
    handleUploadData (e) {
      this.data.iconUrl = e
    },
    emitData (data) {
      this.data = data
      this.$emit('save', data)
    },
    cancelEdit () {
      this.data = { ...this.data, ...{ isEditable: false } }
    },
    editData () {
      this.data = { ...this.data, ...{ isEditable: true } }
    },
    async save () {
      if (!this.data.name) {
        this.isError = true
      } else {
        const params = { ...this.data }
        delete params.isEditable
        params.createdTime = new Date()
        params.updatedTime = new Date()
        this.isLoading = true
        try {
          if (!this.value.isNew) {
            delete params.createdTime
            await ManufactureServices.update(params, this.value.id)
          } else {
            delete params.isNew
            await ManufactureServices.save(params)
          }
          params.isEditable = false
          params.createdTime = this.data.createdTime
          this.emitData(params)
        } catch (e) {
          this.isError = true
        }
        this.isLoading = false
      }
    },
    async remove () {
      await ManufactureServices.delete(this.data.id)
      this.$emit('delete', this.data.id)
    }
  },
  watch: {
    value (val) {
      this.data = val
    }
  },
  mounted () {
    this.data = this.value
  }
}
</script>
<style lang="scss" scoped></style>
