<template>
  <div class="card mb-3">
    <div class="card-header bg-danger text-white">
      Vehicles
    </div>
    <div class="card-body" v-if="isLoading">
      <LoadingComponent/>
    </div>
    <div
      v-else
      style="max-height: 300px; overflow-y: scroll"
      class="card-body">
      <table
        class="table table-striped">
        <tr>
          <th>
            Brand
          </th>
          <th>
            Model
          </th>
          <th>
            Vehicle Type
          </th>
          <th>
            Plate Number
          </th>
          <th>
            Type
          </th>
          <th>
            Updated Time
          </th>
        </tr>
        <tr :key="val.id" v-for="val in data">
          <td>
            {{ val.vehicle.brand }}
          </td>
          <td>
            {{ val.vehicle.model }}
          </td>
          <td>
            {{ val.vehicle.type }}
          </td>
          <td>
            {{ val.plateNumber }}
          </td>
          <td>
            {{ val.type }}
          </td>
          <td>
            {{ $helper.dateFormatter(val.updatedTime) }}
          </td>
         </tr>
      </table>
    </div>
  </div>
</template>
<script>
import UserServices from '@/service/UserServices'
import LoadingComponent from '@/components/misc/LoadingComponent'

export default {
  name: 'UserVehicleComponent',
  components: { LoadingComponent },
  data () {
    return {
      isLoading: true,
      data: []
    }
  },
  methods: {
    async get () {
      this.isLoading = true

      try {
        const res = await UserServices.vehicle(this.$route.params.id)
        if (res) this.data = res.data
      } catch (e) {
        console.log('error', e, e.response)
      }

      this.isLoading = false
    }
  },
  mounted () {
    this.get()
  }
}
</script>
<style lang="scss" scoped></style>
