import { render, staticRenderFns } from "./StoreScreen.vue?vue&type=template&id=f04644be&scoped=true&"
import script from "./StoreScreen.vue?vue&type=script&lang=js&"
export * from "./StoreScreen.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f04644be",
  null
  
)

export default component.exports