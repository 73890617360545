import Http from '../../utils/Http'

class OrderServices {
  constructor() {
    this.url = process.env.VUE_APP_API_URL + 'orders'
  }

  getAll(params) {
    return Http.get(this.url, params)
  }

  get(id) {
    return Http.get(this.url + '/' + id, {})
  }

  update(params, id) {
    return Http.patch(this.url + '/' + id, params)
  }

  save(params) {
    return Http.post(this.url, params)
  }

  delete(id) {
    return Http.delete(this.url + '/' + id, {})
  }

  confirm(id) {
    return Http.post(this.url + '/' + id + '/confirm', {})
  }

  complete(id) {
    return Http.post(this.url + '/' + id + '/complete', {})
  }

  count() {
    return Http.get(this.url + '/counts', {})
  }

  reject(id) {
    return Http.post(`${this.url}/${id}/reject`, {})
  }
}

export default new OrderServices()
