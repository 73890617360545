<template>
  <LoadingComponent />
</template>
<script>
import LoadingComponent from '@/components/misc/LoadingComponent'
export default {
  name: 'Logout',
  components: { LoadingComponent },
  methods: {
    logout () {
      this.$store.commit('auth/reset_user')
      this.$router.push('/login')
    }
  },
  mounted () {
    this.logout()
  }
}
</script>
<style lang="scss" scoped></style>
