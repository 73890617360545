<template>
  <div class="row" data-app>
    <PageHeaderComponent
      :label="`List of Member`"
    />
    <MemberSearchComponent/>
    <div class="col-12 mb-3">
      <LoadingComponent v-if="isLoading"/>
      <v-data-table
        v-else
        :headers="headers"
        :items="data"
        :items-per-page="15"
        class="elevation-1"
        :search="search"
        :hide-default-footer="true"
      >
        <template v-slot:item="{ item }">
          <MemberItemComponent :data="item" @delete="handleDelete"/>
        </template>
      </v-data-table>
    </div>
    <div class="col-12">
      <PaginationComponent/>
    </div>
  </div>
</template>
<script>
import PageHeaderComponent from '@/components/misc/PageHeaderComponent'
import MemberSearchComponent from '@/views/member/MemberSearchComponent'
import LoadingComponent from '@/components/misc/LoadingComponent'
import MemberItemComponent from '@/views/member/MemberItemComponent'
import UserServices from '@/service/UserServices'
import PaginationComponent from "@/components/misc/PaginationComponent";

export default {
  name: 'MemberScreen',
  components: {PaginationComponent, PageHeaderComponent, MemberSearchComponent, LoadingComponent, MemberItemComponent},
  data() {
    return {
      isLoading: true,
      search: '',
      data: [],
      page: 1,
      headers: [
        {text: 'Member ID', value: 'id'},
        {text: 'Phone', value: 'phone'},
        {text: 'Email', value: 'email'},
        {
          text: 'Member Name',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {text: 'Registration Date', value: 'updatedDate'},
        {text: 'Actions', value: 'actions', sortable: false}
      ]
    }
  },
  watch: {
    '$route.query': function (val) {
      const {page} = val
      if (page && !isNaN(Number(page)) && page > 0) 
      { this.getAll(page) } else {
        this.getAll(page)
      }
    },
  },
  computed: {
    query() {
      return this.$route.query
    },
  },
  methods: {
    handleDelete(e) {
      this.data = this.data.filter((v) => {
        return v.id !== e
      })
    },
    async getAll(page = 1) {
      this.isLoading = true
      const limit = 15
      try {
        const params = {
          filter: {
            where: {
              realm: 'member'
            },
            order: ['createdTime DESC'],
            limit,
            skip: page > 0 ? (page - 1) * limit : 0
          }
        }

        const {search} = this.query

        if (search) {
          params.filter.where = {
            ...params.filter.where,
            ...{
              and: [
                {
                  or: [
                    {id: search},
                    {name: {like: `%${search}%`}},
                    {email: search},
                    {mobile: search},
                    {membershipId: search},
                    {citizenId: search}
                  ]
                }
              ]
            }
          }
        }

        params.filter = JSON.stringify(params.filter)

        const res = await UserServices.getAll(params)
        if (res && res.data) this.data = res.data

        this.data = this.data.map((v) => {
          v.isEditable = false
          return v
        })
      } catch (e) {
        console.log('get data user', e, e.response)
      }
      this.isLoading = false
    },
    addAdmin() {
      this.data.unshift({
        activeTime: '',
        authorizationCode: null,
        authorizationTime: null,
        branchCode: null,
        citizenId: null,
        class: '',
        configs: {},
        createdTime: '',
        email: '',
        employeeId: null,
        gender: '',
        licenseId: null,
        membershipId: null,
        mobile: '',
        name: '',
        realm: '',
        redeemCode: null,
        roles: ['admin'],
        type: null,
        updatedTime: '',
        isEditable: true
      })
    },
  },
  mounted() {
    const {page} = this.$route.query
    this.getAll(page || 1)
  }
}
</script>
<style lang="scss" scoped></style>
