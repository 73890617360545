<template>
  <tr>
    <td>
      {{ data.name }}
    </td>
    <td>
      {{ data.manufactureId }}
    </td>
    <td>
      {{ data.sku }}
    </td>
    <td nowrap>
      {{ data.category }} / {{data.subCategory}} / {{data.seriesCategory}}
    </td>
    <td>
      {{ data.price}}
    </td>
    <td nowrap>
      <router-link v-tooltip="'Product Assets'" :to="{
        name: 'product-assets',
        params: {
          id: data.id
        }
      }" class="btn btn-secondary btn-sm">
        <i class="fas fa-images"></i>
      </router-link>
    </td>
    <td>
      <div
        @click="$emit('expand')"
        class="btn btn-sm">
        <i
          :class="[isExpanded ? 'fa-chevron-down' : 'fa-chevron-right']"
          class="fas"></i>
      </div>
    </td>
  </tr>
</template>
<script>
import ProductServices from '@/service/ProductServices'

export default {
  name: 'ProductItemComponent',
  components: {},
  props: {
    data: {
      type: Object,
      required: true
    },
    isExpanded: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async remove () {
      this.isLoading = true
      try {
        await ProductServices.delete(this.data.id)
        this.$emit('delete', this.data.id)
      } catch (e) {
        console.log('error delete product', e, e.response)
      }

      this.isLoading = false
    }
  }
}
</script>
<style lang="scss" scoped></style>
