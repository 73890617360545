import Http from '../../utils/Http'

class ProductServices {
  constructor () {
    this.url = process.env.VUE_APP_API_URL + '/products'
  }

  getAll (params) {
    return Http.get(this.url, params)
  }

  get (id) {
    return Http.get(this.url + '/' + id, {})
  }

  update (params, id) {
    return Http.patch(this.url + '/' + id, params)
  }

  save (params) {
    return Http.post(this.url, params)
  }

  delete (id) {
    return Http.delete(this.url + '/' + id, {})
  }

  sync () {
    return Http.post(this.url + '/sync', {})
  }
}

export default new ProductServices()
