<template>
    <div id="printMe" class="main">
        <div class="container">
            <div class="row">
                <div class="col-6 text-left">
                    <img width="150px" alt="Proban Logo" class="img-fluid" src="../../assets/images/probanlogo.jpeg">
                </div>
                <div class="col-6 text-right mt-4 pt-4">
                    <b>PT Proban Otsburg Trisakti</b><br>
                    NPWP: 70.914.313.5-013.000<br>
                    Jl. Palmerah Barat No. 8 RT/RW 03/03<br>
                    Jakartq Selatan
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-3 text-left">
                    No Transaksi
                </div>
                <div v-if="!isLoading" class="col-9 text-left">
                    : {{ data.invoiceNumber }}
                </div>
                <div v-if="!isLoading" class="col-3 text-left">Kode Cabang</div>
                <div v-if="!isLoading" class="col-9 text-left">: {{ data.merchant.id }}</div>
                <div v-if="!isLoading" class="col-3 text-left">Tanggal</div>
                <div v-if="!isLoading" class="col-9 text-left">: {{ $helper.dateFormatter(data.createdTime, true)}}</div>
            </div>
            <div class="row mt-4 thead">
                <div class="col-3">
                        Barang
                </div>
                <div class="col-3">
                        Harga
                </div>
                <div class="col-3">
                        Jumlah
                </div>
                <div class="col-3">
                        Total
                </div>
            </div>
            <div v-if="!isLoading">
                <div class="row" v-for="item in data.items" :key="item.id">
                    <div class="col-3">
                        {{ item.product.name }}
                    </div>
                    <div class="col-3">
                        IDR {{ $helper.numberFormatter(item.price)}}
                    </div>
                    <div class="col-3">
                        {{ item.quantity }}
                    </div>
                    <div class="col-3">
                        IDR {{ $helper.numberFormatter(subTotalPrice)}}
                    </div>
                </div>
                <div class="row total">
                    <div class="col-9"><b>TOTAL</b></div>
                    <div class="col-3">IDR {{ $helper.numberFormatter(totalPrice) }}</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12 mt-4">
                    <b>Terima kasih atas kunjungan Anda</b>
                    <p>Hubungi kami WA: 0815-9013-133<br>
                    Instagram @probanmotoparts
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import OrderServices from '@/service/OrderServices'
export default {
  name: 'DownloadInvoice',
  data () {
    return {
      isLoading: true,
      data: null
    }
  },
  methods: {
    async getDetail () {
      this.isLoading = true
      const filter = {
        include: [
          {
            relation: 'items',
            scope: {
              order: 'createdTime ASC',
              include: [{
                relation: 'product',
                scope: {
                  include: [{
                    relation: 'assets',
                    scope: {
                      limit: 1
                    }
                  }]
                }
              }]
            }
          },
          {
            relation: 'user'
          },
          {
            relation: 'merchant'
          }
        ],
        where: {
          id: this.$route.params.id
        }
      }
      try {
        const res = await OrderServices.getAll(`filter=${JSON.stringify(filter)}`)
        if (res.data) this.data = res.data[0]
        console.log(this.data)
      } catch (e) {
        console.log('get trx error')
      }
      this.isLoading = false
    }
  },
  mounted () {
    this.getDetail()
  },
  computed: {
    subTotalPrice () {
      let total = 0
      if (this.data.items.length) {
        this.data.items.map((v) => {
          total += (v.price * v.quantity)
        })
      }
      return total
    },
    totalPrice () {
      return this.subTotalPrice
    }
  }
}
</script>
<style lang="scss" scoped>
.main{ background-color:#fff!important; width:100%; height:100%;}
.thead, .total{
    border-top: solid 1px #999;
    border-bottom: solid 1px #999;
}
</style>
