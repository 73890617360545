<template>
  <select
    :required="isRequired"
    v-model="select" class="form-control">
    <option value=""> Select Region </option>
    <option v-for="val in options"
            :key="val.id"
            :value="val.id">
      {{ val.name }}
    </option>
  </select>
</template>
<script>
import RegionServices from '@/service/RegionServices'

export default {
  name: 'SelectRegionComponent',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      select: '',
      options: []
    }
  },
  watch: {
    value (val) {
      this.selected = val
    },
    select (val) {
      this.$emit('input', val)
    }
  },
  methods: {
    async get () {
      try {
        const res = await RegionServices.getAll({})
        if (res && res.data) this.options = [...this.options, ...res.data]
      } catch (e) {
        console.log('get region list error', e, e.response)
      }
    }
  },
  mounted () {
    this.get()
    if (this.value) this.select = this.value
  }
}
</script>
<style lang="scss" scoped></style>
