<template>
  <tr>
    <td>
      {{ data.id }}
    </td>
    <td>
      {{ data.name }}
    </td>
    <td>
      {{ data.discountType }}
    </td>
    <td>
      {{ data.discountValue }}
    </td>
    <td>
      {{ data.usageType}}
    </td>
    <td>
      {{ data.status}}
    </td>
    <td>
      {{ $helper.dateFormatter(data.createdTime)}}
    </td>
    <td>
      {{ $helper.dateFormatter(data.updatedTime)}}
    </td>
    <td nowrap>
      <router-link v-tooltip="'Edit Voucher'" :to="{
        name: 'voucher-detail',
        params: {
          id: data.id
        }
      }" v-if="isAdmin" class="btn btn-secondary btn-sm mr-2">
        <i class="fas fa-edit"></i>
      </router-link>
      <!-- <div
        v-if="isAdmin"
        v-tooltip="'Delete'"
        @click.stop="remove"
        class="btn btn-sm btn-danger">
        <i class="fas fa-trash"></i>
      </div> -->
    </td>
    <td>
      <div
        @click="$emit('expand')"
        class="btn btn-sm">
        <i
          :class="[isExpanded ? 'fa-chevron-down' : 'fa-chevron-right']"
          class="fas"></i>
      </div>
    </td>
  </tr>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'VoucherItemcomponent',
  props: {
    data: {
      type: Object,
      required: true
    },
    isExpanded: {
      type: Boolean,
      default: false
    }
  },
  methods: {},
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isMerchant', 'isOperator'])
  }
}
</script>
<style lang="scss" scoped></style>
