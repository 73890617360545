<template>
  <v-data-table
    :headers="headers"
    :items="data"
    :items-per-page="15"
    class="elevation-1"
    :hide-default-footer="true"
  >
    <template v-slot:item="{ item, expand, isExpanded }">
      <TransactionItemComponent
        :data="item"
        @expand="expand(!isExpanded)"
        :isExpanded="isExpanded"
      />
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <NewOrderItemComponent :data="item" />
      </td>
    </template>
  </v-data-table>
</template>
<script>
import TransactionItemComponent from '@/views/transaction/component/TransactionItemComponent'
import NewOrderItemComponent from '@/views/dashboard/component/NewOrderItemComponent'
export default {
  name: 'TransactionTableComponent',
  components: { NewOrderItemComponent, TransactionItemComponent },
  props: {
    data: {
      type: Array, // should be array of order
      required: true
    }
  },
  data () {
    return {
      headers: [
        {
          text: 'Invoice Number',
          align: 'start',
          sortable: false,
          value: 'invoiceNumber'
        },
        {
          text: 'Service Code',
          align: 'start',
          sortable: false,
          value: 'invoiceNumber'
        },
        {
          text: 'Store',
          align: 'start',
          sortable: false,
          value: 'iconUrl'
        },
        {
          text: 'Transaction Date',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Service Date',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Customer Name',
          align: 'start',
          sortable: false,
          value: 'id'
        },
        { text: 'Transaction Amount', value: 'createdTime' },
        { text: 'Status', value: status },
        { text: 'Toggle', value: 'toggle', sortable: false }
      ]
    }
  }
}
</script>
<style lang="scss" scoped></style>
