<template>
  <tr>
    <td>
      {{ data.brand }}
    </td>
    <td>
      {{ data.model }}
    </td>
    <td>
      {{ data.type }}
    </td>
    <td>
      <router-link :to="{
            name: 'detail-vehicles',
            params: {
              id: data.id
            }
          }" class="btn btn-sm btn-secondary mr-3" v-tooltip="'Edit Data'">
        <i class="fas fa-edit"></i>
      </router-link>
      <div
        v-if="isAdmin"
        v-tooltip="'Delete'"
        @click.stop="remove"
        class="btn btn-sm btn-danger">
        <i class="fas fa-trash"></i>
      </div>
    </td>
    <td>
      <div
        @click="$emit('expand')"
        class="btn btn-sm">
        <i
          :class="[isExpanded ? 'fa-chevron-down' : 'fa-chevron-right']"
          class="fas"></i>
      </div>
    </td>
  </tr>
</template>
<script>
import { mapGetters } from 'vuex'
import VehiclesServices from '@/service/VehiclesServices'

export default {
  name: 'VehicleItemComponent',
  props: {
    data: {
      type: Object,
      required: true
    },
    isExpanded: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async remove () {
      await VehiclesServices.delete(this.data.id)
      this.$emit('delete', this.data.id)
    }
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isMerchant', 'isOperator'])
  }
}
</script>
<style lang="scss" scoped>
.card-footer {
  background-color: #eee;

  &.inactive {
    height: 0;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
  }

  &.active {
    height: auto;
  }
}
</style>
