<template>
    <div class="row">
        <PageHeaderComponent
        :label="`Edit Member`"
        />

        <div class="col-12 mb-3">
            <div class="card">
                <div class="card-header">
                Edit Member
                </div>
                <div class="card-body text-left">
                    <form @submit.prevent="submit">
                        <div class="form-group">
                            <label for="">Member Name</label>
                            <input type="text" class="form-control" required v-model="data.name">
                        </div>
                        <div class="form-group">
                            <label for="">Email Address</label>
                            <input type="text" class="form-control" required v-model="data.email">
                        </div>
                        <div class="form-group">
                            <label for="">Phone Number</label>
                            <input type="text" class="form-control" required v-model="data.mobile">
                        </div>
                        <div class="form-group">
                            <label for="">Citizen ID Number</label>
                            <input type="text" class="form-control" v-model="data.citizenId">
                        </div>
                        <div class="form-group">
                            <label for="">Gender</label>
                            <select v-model="data.gender" class="form-control" required>
                                <option value="male">Male</option>
                                <option value="female"> Female</option>

                            </select>
                        </div>
                        <div class="form-group">
                            <button class="btn btn-success btn-block">SAVE</button>
                        </div>

                        <div v-if="isLoading" class="col-12">
                            <LoadingComponent/>
                        </div>

                        <div
                            v-if="isError"
                            class="alert alert-danger">
                            <span v-if="typeof isError === 'string'" v-html="isError">
                            </span>
                            <span v-else>
                              Something wrong please try again
                            </span>
                        </div>

                        <div
                          v-if="isSuccess"
                          class="alert alert-success col-12">
                          Success updating data
                        </div>

                        <div v-if="isSuccess | isError" class="text-left">
                          <button class="btn btn-link" @click="$router.go(-1)">Go back</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PageHeaderComponent from '@/components/misc/PageHeaderComponent'
import LoadingComponent from '@/components/misc/LoadingComponent'
import UserServices from '@/service/UserServices'
import Helper from '../../../utils/Helper'

export default {
  name: 'EditMember',
  components: { PageHeaderComponent, LoadingComponent },
  data () {
    return {
      isLoading: false,
      isError: false,
      isSuccess: false,
      data: {
        createdTime: new Date(),
        updatedTime: new Date(),
        name: '',
        email: '',
        mobile: '',
        gender: '',
        citizenId: ''
      }
    }
  },
  methods: {
    async submit () {
      this.isLoading = true
      this.isError = false
      this.isSuccess = false
      const params = {
        name: this.data.name,
        email: this.data.email,
        mobile: this.data.mobile,
        gender: this.data.gender,
        citizenId: this.data.citizenId ?? undefined,
        realm: this.data.realm,
        createdTime: this.data.createdTime,
        updatedTime: new Date(),
        activeTime: this.data.activeTime,
        class: this.data.class
      }
      try {
        const { id } = this.$route.params
        let res
        if (!id) {
          res = await UserServices.save(params)
          if (res) await this.$router.push({ name: 'members' })
        } else {
          delete params.id
          res = await UserServices.update(params, id)
        }
        this.isSuccess = true
      } catch (e) {
        this.isError = Helper.generateErrorMessage(e.response)
        console.log('edit user error', this.isError)
      }

      this.isLoading = false
    },
    async getDetail (id) {
      this.isLoading = true
      try {
        const res = await UserServices.get(id)
        if (res && res.data) this.data = res.data
      } catch (e) {
        console.log('get user detail error', e, e.response)
        this.isError = true
      }
      this.isLoading = false
    }
  },
  mounted () {
    const { id } = this.$route.params
    if (id) this.getDetail(id)
  }
}
</script>
