<template>
  <PulseLoader :loading="true" />
</template>
<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
export default {
  name: 'LoadingComponent',
  components: {
    PulseLoader
  }
}
</script>
<style lang="scss" scoped></style>
