<template>
  <tr>
    <td>
      {{ data.title }}
    </td>
    <td>
      {{ data.status }}
    </td>
    <td>
      {{ $helper.dateFormatter(data.createdTime)}}
    </td>
    <td>
      {{ $helper.dateFormatter(data.updatedTime)}}
    </td>
    <td>
      <div
        v-tooltip="'Edit'"
        @click="navigate"
        class="btn btn-success btn-sm mr-1">
        <i class="fas fa-edit"></i>
      </div>
      <div
        v-if="isAdmin"
        v-tooltip="'Delete'"
        @click.stop="remove"
        class="btn btn-danger btn-sm">
        <i class="fas fa-trash"></i>
      </div>
    </td>
  </tr>
</template>
<script>
import { mapGetters } from 'vuex'
import PromoServices from '@/service/PromoServices'

export default {
  name: 'PromoItemListComponent',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isMerchant', 'isOperator'])
  },
  methods: {
    navigate () {
      this.$router.push({
        name: 'edit-promo',
        params: {
          id: this.data.id
        }
      })
    },
    async remove () {
      console.log('clicked')
      try {
        await PromoServices.delete(this.data.id)
        this.$emit('delete', this.data.id)
      } catch (e) {
        console.log('failed delete', e, e.response)
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
