<template>
  <ModalComponent
    :is-show="isShow"
    :is-header="false"
    :is-footer="false"
    @close="$emit('close')"
  >
    <template v-slot:body>
      <div class="text-left">
        <h5 class="font-weight-bold">
          Add new admin
        </h5>
        <div
          v-if="isError"
          class="alert alert-danger">
          Something went wrong
        </div>
        <div
          v-if="isSuccess"
          class="alert alert-success">
          Success update
        </div>
        <div v-if="isLoading">
          <LoadingComponent/>
        </div>
        <form
          v-else
          @submit.prevent="update">
          <div class="form-group">
            <label for="">
              Admin Name
            </label>
            <input class="form-control" type="text" v-model="data.name" required>
          </div>
          <div class="form-group">
            <label for="">
              Admin Email
            </label>
            <input class="form-control" type="email" v-model="data.email" required>
          </div>
          <div class="form-group">
            <label for="">
              Admin Password
            </label>
            <input class="form-control" type="password" v-model="data.password" required>
          </div>
          <div class="form-group">
            <label for="">
              Admin Phone
            </label>
            <input class="form-control" type="text" v-model="data.mobile" required>
          </div>
          <div class="form-group">
            <label for="">
              Role
            </label>
            <select v-model="role" class="form-control" required>
              <option value="admin"> Admin </option>
              <option value="merchant"> Merchant </option>
              <option value="operator"> Operator </option>
            </select>
          </div>
          <div
            v-if="role === 'merchant'"
            class="form-group">
            <label for="">
              Branch Code
            </label>
            <input type="text" class="form-control" v-model="data.branchCode">
          </div>
          <div class="form-group">
            <button class="btn btn-primary mr-3">
              Save
            </button>
            <div
              @click="$emit('close')"
              class="btn btn-secondary">
              Close
            </div>
          </div>
        </form>
      </div>
    </template>
  </ModalComponent>
</template>
<script>
import ModalComponent from '@/components/misc/ModalComponent'
import LoadingComponent from '@/components/misc/LoadingComponent'
import UserServices from '@/service/UserServices'

const dataDefault = () => {
  return {
    email: '',
    branchCode: '',
    employeeId: '',
    gender: 'male',
    licenseId: '',
    membershipId: '',
    mobile: '',
    name: '',
    realm: 'admin',
    redeemCode: '',
    roles: [],
    type: '',
    promotionAgreed: true
  }
}

export default {
  name: 'AddAdminModalComponent',
  components: { LoadingComponent, ModalComponent },
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      notes: '',
      isSuccess: false,
      isError: false,
      data: dataDefault(),
      role: 'admin'
    }
  },
  methods: {
    async update () {
      this.isLoading = true
      this.isError = false
      this.isSuccess = false

      try {
        this.data.roles = [this.role]
        if (this.role === 'admin' || this.role === 'operator') this.data.branchCode = 'HQPROBAN'
        await UserServices.save(this.data)
        this.data = dataDefault()
        this.isSuccess = true
        this.$emit('showAlert', { message: 'New Admin Created' })
        this.$emit('close')
      } catch (e) {
        this.isError = true
      }

      this.isLoading = false
    }
  }

}
</script>
<style lang="scss" scoped></style>
