<template>
  <div class="row">
    <PageHeaderComponent
      :label="`Add Promo`"
    />
    <div
      v-if="isError"
      class="alert alert-danger">
      Something wrong please try again
    </div>
    <div v-if="isLoading" class="col-12">
      <LoadingComponent/>
    </div>
    <div v-else class="col-12 text-left">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="submit">
            <div class="form-group">
              <label for="">Title</label>
              <input type="text" class="form-control" required v-model="data.title">
            </div>
            <div class="form-group">
              <label for="">Caption</label>
              <input type="text" class="form-control" required v-model="data.caption">
            </div>
            <div class="form-group">
              <label for="">Description</label>
              <div class="row">
                <div class="col-6">
                  <textarea class="form-control" v-model="data.content" @input="update" rows="6"></textarea>
                </div>
                <div class="col-6"  v-if="data.content" v-html="compiledMarkdown"></div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-6">
                  <label for="">Start Date</label>
                  <datetime format="DD/MM/YYYY" v-model="data.startDate"></datetime>
                </div>
                <div class="col-6">
                  <label for="">End Date</label>
                  <datetime format="DD/MM/YYYY" v-model="data.finishDate"></datetime>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="">Notes</label>
              <div class="row">
                <div class="col-6">
                  <textarea v-model="data.notes" class="form-control" rows="6" @input="updateNotes"></textarea>
                </div>
                <div class="col-6" v-html="compiledMarkdownNotes"></div>
              </div>
            </div>
            <div class="form-group">
              <label for="">Status</label>
              <select v-model="data.status" class="form-control" required>
                <option value="sticky"> Sticky</option>
                <option value="active"> Active</option>
                <option value="inactive"> Inactive</option>
                <option value="product"> Product</option>

              </select>
            </div>
            <hr>
            <div class="form-group">
              <label for="">Image</label>
              <UploadBigFileComponent
                :url="data.imageUrl"
                @uploaded="data.imageUrl = $event"
              />
            </div>
            <div class="form-group">
              <button class="btn btn-primary btn-block">SAVE</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeaderComponent from '@/components/misc/PageHeaderComponent'
import LoadingComponent from '@/components/misc/LoadingComponent'
import PromoServices from '@/service/PromoServices'
import 'vue-wysiwyg/dist/vueWysiwyg.css'
import datetime from 'vuejs-datetimepicker'
import moment from 'moment'
import UploadBigFileComponent from '@/components/misc/UploadBigFileComponent'
import marked from 'marked'
import _ from 'lodash'
export default {
  name: 'AddPromoScreen',
  components: { UploadBigFileComponent, LoadingComponent, PageHeaderComponent, datetime },
  data () {
    return {
      isLoading: false,
      isError: false,
      data: {
        createdTime: new Date(),
        updatedTime: new Date(),
        title: '',
        caption: '',
        content: '',
        notes: '',
        status: 'active',
        startDate: '',
        finishDate: '',
        imageUrl: ''
      }
    }
  },
  computed: {
    compiledMarkdown: function () {
      return marked(this.data.content, { sanitize: true })
    },
    compiledMarkdownNotes: function () {
      return marked(this.data.notes, { sanitize: true })
    }
  },
  methods: {
    update: _.debounce(function (e) {
      this.input = e.target.value
    }, 300),
    updateNotes: _.debounce(function (e) {
      this.input = e.target.value
    }, 300),
    async submit () {
      this.isLoading = true
      this.isError = false
      const params = this.data
      params.startDate = moment(params.startDate, 'DD/MM/YYYY')
      params.finishDate = moment(params.finishDate, 'DD/MM/YYYY')
      params.updatedTime = moment(new Date(), 'DD/MM/YYYY')
      try {
        const { id } = this.$route.params
        let res
        if (!id) {
          res = await PromoServices.save(params)
          console.log(params)
          if (res) await this.$router.push({ name: 'promos' })
        } else {
          delete params.id
          res = await PromoServices.update(params, id)
          this.data.updatedTime = moment(new Date()).format('DD/MM/YYYY')
          this.data.startDate = moment(this.data.startDate).format('DD/MM/YYYY')
          this.data.finishDate = moment(this.data.finishDate).format('DD/MM/YYYY')
          if (res) await this.$router.push({ name: 'promos' })
        }
      } catch (e) {
        console.log('create promo error', e, e.response)
        this.isError = true
      }

      this.isLoading = false
    },
    async getDetail (id) {
      this.isLoading = true
      try {
        const res = await PromoServices.get(id)
        if (res && res.data) this.data = res.data
        this.data.startDate = moment(this.data.startDate).format('DD/MM/YYYY')
        this.data.finishDate = moment(this.data.finishDate).format('DD/MM/YYYY')
      } catch (e) {
        console.log('get promo detail error', e, e.response)
        this.isError = true
      }
      this.isLoading = false
    }
  },
  mounted () {
    const { id } = this.$route.params
    if (id) this.getDetail(id)
  }
}
</script>
<style lang="scss" scoped></style>
