import Http from '../../utils/Http'

class UserServices {
    constructor() {
        this.url = process.env.VUE_APP_API_URL + '/users'
    }

    getAll(params) {
        return Http.get(this.url, params)
    }

    count(params) {
      return Http.get(this.url + '/count', params)
    }

    getPoint(id) {
        return Http.get(process.env.VUE_APP_API_URL + '/proban/checkMemberPoints?memberId=' + id)
    }

    get(id) {
        return Http.get(this.url + '/' + id)
    }

    userVouchers(id, params) {
        return Http.get(this.url + '/' + id + '/vouchers', params)
    }

    ResetPassword(id) {
        return Http.delete(this.url + '/' + id + '/credentials', {})
    }

    update(params, id) {
        return Http.patch(this.url + '/' + id, params)
    }

    setActiveDevice(params, userid, id) {
        return Http.patch(this.url + '/' + userid + '/devices/' + id, params)
    }

    setInactiveDevice(params, userid, id) {
        return Http.patch(this.url + '/' + userid + '/devices/' + id, params)
    }

    save(params) {
        return Http.post(this.url, params)
    }

    delete(id) {
        return Http.delete(this.url + '/' + id, {})
    }

    device(id) {
        return Http.get(this.url + '/' + id + '/devices', {})
    }

    vehicle(id) {
        return Http.get(this.url + '/' + id + '/vehicles', { filter: JSON.stringify({ include: [{ relation: 'vehicle' }] }) })
    }

    updatePut(params, id) {
        return Http.put(this.url + '/' + id, params)
    }

    changePassword(id, params) {
        return Http.post(this.url + '/' + id + '/change-password', params)
    }

    resetPassword(id, params) {
        return Http.post(this.url + '/' + id + '/reset-password', params)
    }

    login(params) {
        return Http.post(this.url + '/login', params)
    }

    getMe() {
        return Http.get(this.url + '/me', {})
    }
}

export default new UserServices()
