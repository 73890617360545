<template>
  <div
    v-if="!isLoading"
    class="card textLeft">
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <b>Voucher</b>: {{ mutateData.name ? mutateData.name : '-' }} <br>
          <b>Discount Type</b>: {{ mutateData.discountType }} <br>
          <b>Discount Value</b>: {{ mutateData.discountValue }}<br>
          <b>Terms Of Use</b>: {{ mutateData.termsOfUse }}
        </div>
        <div class="col-6">
          <b>Description</b>: {{ mutateData.description }} <br>
          <b>Notes</b>: {{ mutateData.notes }}<br>
          <b>Product SKU</b>: {{ mutateData.product.sku }}<br>
          <b>Created Time</b>: {{ this.$helper.dateFormatter(mutateData.createdTime, true) }} <br>
          <b>Updated Time</b>: {{ this.$helper.dateFormatter(mutateData.updatedTime, true) }}
        </div>
        <div
        :style="{ backgroundImage : `url(${assetUrl})`}"
        class="standard-thumbnail border">
         </div>
      </div>
    </div>
  </div>
</template>
<script>
const image = require('@/assets/images/image-placeholder.jpeg')
export default {
  name: 'VoucherItemDetail',
  components: { },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      mutateData: { ...this.data }
    }
  },
  watch: {
    data (val) {
      this.mutateData = { ...val }
    }
  },
  methods: {},
  computed: {
    assetUrl () {
      if (this.data.imageUrl) {
        return this.data.imageUrl ? this.data.imageUrl : image
      }
      return image
    }
  }
}
</script>
<style lang="scss" scoped>
.textLeft {
  text-align: left;
}
</style>
