<template>
  <div class="row">
    <PageHeaderComponent
      :label="`List of Promos`"
      :button-label="`Add Promo`"
      @btn-click="addPromo"
    />
    <div class="col-12">
      <LoadingComponent v-if="isLoading" />
      <v-data-table
        v-else
        :headers="headers"
        :items="promos"
        :items-per-page="15"
        class="elevation-1"
        :search="search"
      >
        <template v-slot:item="{ item }">
          <PromoItemListComponent :data="item"
            @delete="handleDelete"
          />
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import PageHeaderComponent from '@/components/misc/PageHeaderComponent'
import PromoItemListComponent from '@/views/promo/PromoItemListComponent'
import LoadingComponent from '@/components/misc/LoadingComponent'
import PromoServices from '@/service/PromoServices'
export default {
  name: 'PromoScreen',
  components: { LoadingComponent, PromoItemListComponent, PageHeaderComponent },
  data () {
    return {
      isLoading: true,
      search: '',
      promos: [],
      headers: [
        {
          text: 'Title',
          align: 'start',
          sortable: true,
          value: 'title'
        },
        {
          text: 'Status',
          align: 'start',
          sortable: true,
          value: 'status'
        },
        { text: 'Created Date', value: 'createdTime' },
        { text: 'Updated Time', value: 'createdTime' },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    }
  },
  methods: {
    addPromo () {
      this.$router.push({
        name: 'add-promo'
      })
    },
    async get () {
      this.isLoading = true
      try {
        const res = await PromoServices.getAll({})
        if (res && res.data) this.promos = [...this.promos, ...res.data]
      } catch (e) {
        console.log('get promo error', e, e.response)
      }

      this.isLoading = false
    },
    handleDelete (e) {
      this.promos = this.promos.filter((v) => {
        return v.id !== e
      })
    }
  },
  mounted () {
    this.get()
  }
}
</script>
<style lang="scss" scoped></style>
