import Http from '../../utils/Http'

class UserDeletionServices {
    constructor() {
        this.url = process.env.VUE_APP_API_URL + '/users'
    }

    sendCode(params) {
      return Http.post(this.url + '/deletion/code', params)
    }

    deleteUser(params) {
      return Http.post(this.url + '/deletion', params)
    }
}

export default new UserDeletionServices()
