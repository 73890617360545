<template>
  <tr>
    <td>
      {{ data.id }}
    </td>
    <td>
      {{ data.regionId }}
    </td>
    <td>
      {{ data.branchType }}
    </td>
    <td>
       {{ data.name }}
    </td>
    <td>
      {{ data.status}}
    </td>
    <td>{{ data.phoneNumber }}</td>
    <td>
      <div
        :style="{ backgroundImage : `url(${assetUrl})`}"
        class="standard-thumbnail border">
      </div>
    </td>
    <td nowrap>
      <div
        v-if="isAdmin"
        v-tooltip="'Edit'"
        @click="navigate"
        class="btn btn-success btn-sm mr-1">
        <div class="fas fa-edit"></div>
      </div>
      <div
        v-if="isAdmin"
        v-tooltip="'Delete'"
        @click.prevent="remove"
        class="btn btn-danger btn-sm">
        <div class="fas fa-trash"></div>
      </div>
    </td>
  </tr>
</template>
<script>
import { mapGetters } from 'vuex'
import MerchantServices from '@/service/MerchantServices'
const image = require('@/assets/images/image-placeholder.jpeg')
export default {
  name: 'StoreItemComponent',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    navigate () {
      this.$router.push({
        name: 'store-detail',
        params: {
          id: this.data.id
        }
      })
    },
    async remove () {
      try {
        await MerchantServices.delete(this.data.id)
      } catch (e) {
        console.log('delete failed', e, e.response)
      }
    }
  },
  computed: {
    assetUrl () {
      if (this.data.imageUrl) {
        return this.data.imageUrl ? this.data.imageUrl : image
      }
      return image
    },
    ...mapGetters('auth', ['isAdmin', 'isMerchant', 'isOperator'])
  }
}
</script>
<style lang="scss" scoped></style>
