<template>
  <div class="col-12">
    <div class="card bg-secondary">
      <div
        v-if="isLoading"
        class="card-body">
        <LoadingComponent/>
      </div>
      <div
        v-else
        @click="triggerInput"
        @drop.prevent="addFile"
        class="card-body
        cursor-pointer
        py-5 d-flex align-items-center justify-content-center" @dragover.prevent>
        <div>
          <h1 class="font-weight-bold">
            Drag Image to Upload
          </h1>
        </div>
      </div>
      <input
        @change="uploadFile"
        ref="inputFile"
        type="file"
        style="display: none"
      >
    </div>
  </div>
</template>
<script>
import LoadingComponent from '@/components/misc/LoadingComponent'
import FileServices from '@/service/FileServices'
import ProductAssetService from '@/service/ProductAssetService'

export default {
  name: 'ProductAssetUploadComponent',
  components: { LoadingComponent },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    productId () {
      return this.$route.params.id
    }
  },
  methods: {
    addFile (e) {
      const file = e.dataTransfer.files
      for (let i = 0; i < file.length; i++) {
        this.uploadFile(file[i])
      }
    },
    async uploadFile (file = false) {
      this.isLoading = true
      let input = this.$refs.inputFile.files[0]
      if (!input) {
        input = file
      }
      const params = {
        file: input
      }
      try {
        const res = await FileServices.save(params)
        if (res) {
          const { files } = res.data
          if (files.length > 0 && files[0]) {
            const imageName = files[0].url
            const imageUrl = await this.getFile(imageName)
            this.saveImage(imageUrl)
          }
        }
      } catch (e) {
        console.log('upload image error', e, e.response)
      }
    },
    async saveImage (str) {
      try {
        const params = {
          url: str
        }
        await ProductAssetService.save(this.productId, params)
        this.$emit('uploaded')
      } catch (e) {
        console.log('save image', e, e.response)
      }
      this.isLoading = false
    },
    triggerInput () {
      this.$refs.inputFile.click()
    },
    getFile (str) {
      return FileServices.get(str)
    }
  }
}
</script>
<style lang="scss" scoped></style>
