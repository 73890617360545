<template>
  <tr>
    <td>
      {{ user ? user.name : '-' }}
    </td>
    <td>
      {{ $helper.numberFormatter(mutateData.amount) }}
    </td>
    <td>
      {{ $helper.dateFormatter(mutateData.createdTime, true) }}
    </td>
    <td>
      {{ mutateData.status }}
    </td>
    <td>
      {{ mutateData.notes }}
    </td>
    <td nowrap style="text-align: right;">
      <loading-component v-if="isLoading" />
      <div
        @click="update('rejected')"
        v-if="mutateData.status === 'submitted' && !isLoading"
        v-tooltip="'Reject'"
        class="btn btn-sm btn-danger mr-2"
      >
        Reject
      </div>
      <div
        @click="update('approved')"
        v-if="mutateData.status === 'submitted' && !isLoading"
        v-tooltip="'Approve'"
        class="btn btn-sm btn-primary"
      >
        Approve
      </div>
      <div
        @click="approve"
        v-if="mutateData.status === 'approved' && isAdmin && !isLoading"
        v-tooltip="'Proceed refund'"
        class="btn btn-sm btn-success"
      >
        Refund
      </div>
      <ModalSettledComponent
        @showNotif="showNotif"
        :data="mutateData"
        :is-show="isShow"
        @close="isShow = false"
        @settled="handleSettled"
      />
    </td>
    <td>
      <div @click="$emit('expand')" class="btn btn-sm">
        <i
          :class="[isExpanded ? 'fa-chevron-down' : 'fa-chevron-right']"
          class="fas"
        ></i>
      </div>
    </td>
  </tr>
</template>
<script>
  import LoadingComponent from '@/components/misc/LoadingComponent'
  import RefundService from '@/service/RefundService'
  import ModalSettledComponent from '@/views/refund/components/ModalSettledComponent'
  import { mapGetters } from 'vuex'

  export default {
    name: 'RefundItemComponent',
    components: { ModalSettledComponent, LoadingComponent },
    props: {
      data: {
        type: Object,
        required: true
      },
      isExpanded: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        isLoading: false,
        mutateData: this.data,
        isShow: false
      }
    },
    computed: {
      user() {
        return this.data && this.data.user
      },
      ...mapGetters('auth', ['isAdmin', 'isMerchant', 'isOperator'])
    },
    methods: {
      async update(type) {
        this.isLoading = true
        try {
          const params = {
            status: type
          }

          const res = await RefundService.update(params, this.data.id)
          if (res) {
            this.mutateData.status = type
          }
        } catch (e) {
          console.log('update status false')
        }
        this.isLoading = false
      },
      approve() {
        this.isShow = true
      },
      handleSettled(e) {
        this.mutateData = { ...this.mutateData, ...e }
      },
      showNotif(e) {
        this.$emit('showAlert', { message: e.message })
      }
    }
  }
</script>
<style lang="scss" scoped></style>
