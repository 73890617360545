<template>
  <div class="alert alert-danger">
    {{ message }}
  </div>
</template>
<script>
export default {
  name: 'DangerComponent',
  props: {
    message: {
      type: String,
      default: 'Something Wrong'
    }
  }
}
</script>
<style lang="scss" scoped></style>
